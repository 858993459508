import React from 'react';

import Modal from '../Modal';

class OfferDisclosure extends React.Component {

	close = () => {
		this.props.close();
		
	}
	
	continue = () => {
		window.location.pathname='/forms/request-listing-agent';
		this.props.close();
		
	}

  render() {
		if (this.props.open) {
			return (

				<Modal
					title={"Zero realty list fee promotion"}
					visible={this.props.open}
					close={this.close}
					footer={<div>
						<button className="float-left" onClick={this.continue}>
							Talk with a realtor
						</button>
					</div>}>
					<>

						<div style={{maxHeight: 300, overflow: "auto"}}>
							<p>
								Maximize your savings when you use CapCenter to buy and finance your next home and sell your current one. Subject to the additional limitations below, if you purchase a home using a CapCenter Realtor®, finance that purchase with a CapCenter Mortgage, and sell a home with a CapCenter Realtor®, CapCenter will waive your listing commission!
							</p>
							<p>
								<strong>IMPORTANT DATES:</strong>
							</p>
							<ul>
								<li>You must purchase a home using a CapCenter Realtor® and have entered into a contract to purchase that home between July 1, 2023 and December 31, 2023.</li>
								<li>You must finance the above purchase with a CapCenter Mortgage and keep that mortgage current for at least 6 months.</li>
								<li>You must have a listing appointment with a CapCenter Realtor® between July 1, 2023 and July 31, 2023, and that home must sell within 12 months of the listing appointment date.</li>
							</ul>
							<p>
								<strong>IF YOU SELL FIRST, THEN BUY AND GET A CAPCENTER MORTGAGE:</strong>
							</p>
							<p>
								CapCenter will charge its standard 1.5% listing commission when the home sells. The buyer’s agent’s commission is not included in the offer. After you close on your purchase, CapCenter will confirm whether all eligibility criteria of the offer have been met. If they have, CapCenter will issue a refund check to you in the amount of 1.5% of the sales price of the listed property.
							</p>
							<p>
								For example:
							</p>
							<ul>
								<li>On July 6, 2023, you have a listing appointment with a CapCenter Realtor.® You list that home with CapCenter, and it sells for $400,000 on August 6, 2023.</li>
								<li>On August 7, 2023, you purchase a home using a CapCenter Realtor® and finance the purchase with a CapCenter Zero Closing Cost Mortgage. </li>
							</ul>
							<p>CapCenter will send you a refund check for <strong>$6,000</strong> (1.5% listing commission refund on $400,000 home sale) after the August 7, 2023 purchase closing. These savings are <em>in addition to</em> your Zero Closing Cost <a href="/mortgage-calculator/purchase">savings</a> on your August 7, 2023 mortgage.</p>

							<p>
								<strong>IF YOU BUY AND GET A CAPCENTER MORTGAGE FIRST, THEN SELL:</strong>
							</p>
							<p>
								CapCenter will not charge a listing commission when the home sells (not including the Buyer’s agent’s commission), provided that CapCenter can verify prior to closing that (1) you had a list consultation appointment for that property with a CapCenter Realtor® between July 1, 2023 and July 31, 2023, (2) you also purchased a home with a CapCenter Realtor® between July 1, 2023 and December 31, 2023 and (3) you financed that purchase with a CapCenter Mortgage. If you list a home for sale with a CapCenter Realtor® between July 1, 2023 and July 31, 2023, but your listing appointment occurred prior to July 1, 2023, you are eligible for this offer provided that you purchase another home with a CapCenter Realtor® and finance that purchase with a CapCenter Mortgage.
							</p>
							<p>
								For example:
							</p>
							<ul>
								<li>On July 6, 2023, you purchase a new home using a CapCenter Realtor® and finance the purchase with a CapCenter Zero Closing Cost Mortgage.</li>
								<li>On July 20, 2023, you have a listing appointment with a CapCenter Realtor® to sell your previous home. On August 20, 2023, your home sells. CapCenter will not charge a listing commission at the sale of this home.</li>
							</ul>
							<p>
								<strong>ADDITIONAL LIMITATIONS & DISCLOSURES FOR 1% LIST SAVINGS:</strong>
							</p>
							<p>
								This offer is only available on certain eligible properties. The following properties are excluded:
							</p>
							<ul>
								<li>Second homes</li>
								<li>Investment properties</li>
								<li>Properties owned by a bank and offered as short sales or foreclosures</li>
								<li>Commercial properties</li>
								<li>Unimproved land</li>
								<li>"For rent" properties</li>
								<li>Mobile homes</li>
							</ul>
							<p>
								The Zero % List Savings Offer does not include commission paid to the buyer’s agent, which is customarily paid by the seller. For example, if the buyer’s commission is 3%, and you have not yet satisfied all of the criteria for the offer, the total commission paid at closing of a sold property will be 4.5% of the sales price. For the eligible purchase transaction, CapCenter’s buyer’s commission (selling broker commission, traditionally paid by the seller) must be at least $4,500, and you must occupy the purchased property as your primary residence within 60 days of closing on the purchase.
							</p>
							<p>
								The Zero % List Savings Offer is combinable with CapCenter’s Zero Closing Costs Mortgage Offer. See disclosures and limitations pertaining to the Zero Closing Costs Offer herein. The Zero % List Savings Offer is not combinable with any other offer, including the Realty Buy Savings described herein.
							</p>
							<p>
								Ultimately, the availability of the Zero % List Savings Offer will depend on your agreement(s) in writing to enter into a business relationship with us to represent you in the purchase and/or sale of your home. This offer is does not apply if you do not sell a home using a CapCenter Realtor;® however, in that case, you may be eligible for Realty Buy Savings and/or a CapCenter Zero Closing Costs Mortgage, subject to the terms and conditions of those offers.
							</p>
							<p>
								If you do not maintain the subject mortgage and keep it current for the six consecutive months immediately following closing, you will be ineligible for this offer, and you forfeit any right or claim you may have to the benefits associated with this offer, including any listing commission refund. If you have already received a refund from CapCenter and you pay off your loan early, you agree to reimburse CapCenter in the full amount of your refund within seven days of your loan payoff. This paragraph is not intended to be construed as a prepayment penalty, nor does it supersede or amend any terms of your official loan documents.
							</p>
							<p>
								You must contact CapCenter at customerservice@capcenter.com to claim this offer. You are solely responsible for any tax implications to you resulting from this offer. “CapCenter Mortgage” as used herein refers to a residential mortgage for a 1–4-unit dwelling originated by Capital Center, L.L.C. The mortgage may be serviced by another financial institution. This offer is non-transferrable; the same individual must be the seller, purchaser, and borrower for the respective eligible transactions. This offer is subject to change or cancellation at any time, without advance notice, at the sole discretion of CapCenter.  Additional restrictions/conditions may apply. This is not a commitment to lend.
							</p>
						</div>


					</>


				</Modal>
			);
		} else {
			return <></>
		}
  }
}

export default OfferDisclosure;
