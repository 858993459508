import React, {Component, useState} from 'react';
import OfferDisclosure from './OfferDisclosure';
import './Banner.scss';



const Banner: React.SFC<{
  showBanner: boolean;
  closeBanner: Function;
}> = props => {
  const [showModal, setShowModal] = useState(false);
  const [lastX, setLastX] = useState(0);
  const [lastY, setLastY] = useState(0);

  const openModal = function () {
    setShowModal(true);
  }

  const closeModal = function () {
    setShowModal(false);
  }

  const isSwipeUpEvent = function (clientY: any) {
    const threshold = 25;

    if (lastX === 0 && lastY === 0) {
      return false;
    }

    if (lastY - clientY > threshold) {
      return true;
    }

    return false;
  }

  const handleMouseEvents = function (event: any) {
    event.persist();
    if (event.type === "mousedown") {
      setLastX(event.clientX);
      setLastY(event.clientY);
    } else {
      if (isSwipeUpEvent(event.clientY)) {
        props.closeBanner();
      }

      setLastX(0);
      setLastY(0);
    }
  }

  const handleTouchEvents = function (event: any) {
    event.persist();

    if (event.changedTouches && event.changedTouches.length > 0) {
      if (event.type === "touchstart") {
        setLastX(event.changedTouches[0].clientX);
        setLastY(event.changedTouches[0].clientY);
      } else {
        if (isSwipeUpEvent(event.changedTouches[0].clientY)) {
          props.closeBanner();
        }

        setLastX(0);
        setLastY(0);
      }
    }
  }

  return (
    <>
      <div className={`cc--fixed-banner fixed-top w-100 ${!props.showBanner ? 'hide-banner' : '' }`} onMouseDown={handleMouseEvents} onMouseUp={handleMouseEvents} onTouchStart={handleTouchEvents} onTouchEnd={handleTouchEvents}>
        <div className={'close-banner'} onClick={() => {props.closeBanner()}}><i className="fal fa-times"></i> Close</div>
        <div className={'row'}>
          <div className={'image-group column'}>
          </div>
          <div className={'text-group column'}>
            <div className={'pre-title'}>
              BUNDLE & SAVE
            </div>
            <hr />
            <div className={'title'}>
              1% LISTING FEES when you buy and sell.
            </div>
            <div className={'sub-title'}>
              Begin your zero closing cost journey here.
            </div>
          </div>
          <div className={'btn-group column'}>
            <a href={'/legal/product-offer-details#zero-list-savings'} title={'Learn more'}><button className="btn-primary">Learn more</button></a>
            <a href={'/forms/request-listing-agent'} title={'Talk with a realtor'}><button>Talk with a realtor</button></a>
          </div>
        </div>
      </div>
      <OfferDisclosure
        close={closeModal}
        open={showModal}
      />
    </>
  );
};


export default Banner;
