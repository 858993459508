import React from 'react';
import { AppWrapper, baseRealEstateUrl, baseRealtyUrl, baseUrl } from "@capcenter/shared";
import { AddressSearch, Tooltip, getUrlWithUtmQueryString } from "@capcenter/shared-legacy";
import RegisteredTrademark from '../../assets/registered-trademark.svg';
import RegisteredTrademarkWhite from '../../assets/registered-trademark-white.svg';
import '../Realty.scss';

// Core Page Sections
import FAQSection from '../sections/FAQ';
import RatesSection from '../sections/Rates';
import DataSection from '../sections/Data';
import DescriptionSection from '../sections/Description';
import ProcessSection from '../sections/Process';
import RefinanceSection from '../sections/Refinance';
import ReviewsSection from '../sections/Reviews';
import SavingsSection from '../sections/Savings';
import DownPaymentSection from '../sections/DownPayment';
import NextStepsSection from '../sections/NextSteps';
import ListingsSection from '../sections/Listings';
import HomesForSaleSection from '../sections/HomesForSale';

class RealtyBuy extends React.Component {
  render() {
    return <AppWrapper app="realtyBuy" noHeader>
      <div role="document">
        <main className="realty">
          <section className="section-search-hero">
            <div className="container-fluid">
              <div className="row justify-content-between">
                <article className="search-group buy">
                  <header>
                    <hr className="hero-rule"/>
                    <h1 data-aos="flip-left">
                      ZERO Closing Cost Homes <span><Tooltip
                      arrow
                      position="bottom"
                      html={<div><p>CapCenter's Zero Closing Costs offer is to pay or waive closing costs typically required when processing a home loan.</p><p>Our offer does have limitations, and there are additional costs that apply to some transactions, e.g., investment loans. See buy savings details below.</p></div>}
                    ><i className="fas fa-info-circle"></i></Tooltip></span><br/>with a <a href="/find-best-local-realtor">CapCenter Realtor<img src={RegisteredTrademark} className={"registered-trademark-heading"}/></a>
                    </h1>
                  </header>
                  <div className="tab-container">
                    <div className="tab active"><a href="/best-realtor-agency-buy-home" className={'d-none d-sm-inline-block tab-buy-link'}>Buy</a><a href="/best-realtor-agency-buy-home" className={'d-inline-block d-sm-none tab-buy-link'}>Buy</a></div>
                    <div className="tab"><a href="/best-realtor-agency-sell-home" className={'d-none d-sm-inline-block tab-sell-link'}>Sell</a><a href="/best-realtor-agency-sell-home" className={'d-inline-block d-sm-none tab-sell-link'}>Sell</a></div>
                    <div className="tab"><a href="/get-preapproved-for-a-home-loan" className={'d-none d-sm-inline-block tab-preapproval-link'}>Pre-approval</a><a href="/get-preapproved-for-a-home-loan" className={'d-inline-block d-sm-none tab-preapproval-link'}>Pre-approval</a></div>
                    <div className="tab"><a href="/how-much-is-my-home-worth" className={'d-none d-sm-inline-block tab-homevalue-link'}>Home Value</a></div>
                  </div>
                  <div className="search-bar d-block d-sm-none">
                    <AddressSearch
                      setAddress={(address) => {
                        if (address) {
                          const urlAddress = address.toLowerCase().replace(/\s/g, '-').replace(/,/g, '');
                          window.location.href = getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/address/${urlAddress}`);
                        }
                      }}
                      setAllData={(data) => {
                      }}
                      placeholder={'Address, City, Zip, or Neighborhood'}
                      includeZip={true}
                      noPadding={true}
                      log={false}
                    />
                  </div>
                  <div className="search-bar d-none d-sm-block">
                    <AddressSearch
                      setAddress={(address) => {
                        if (address) {
                          const urlAddress = address.toLowerCase().replace(/\s/g, '-').replace(/,/g, '');
                          window.location.href = getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/address/${urlAddress}`);
                        }
                      }}
                      setAllData={(data) => {
                      }}
                      placeholder={'Address, City, Zip Code, or Neighborhood'}
                      includeZip={true}
                      noPadding={true}
                      log={false}
                    />
                  </div>
                </article>
                <article className="hero-right buy">
                </article>
              </div>
            </div>
          </section>

          <SavingsSection />

          <RatesSection props={this.props} />

          <DownPaymentSection props={this.props} />

          <DataSection props={this.props} />

          <ReviewsSection props={this.props} />

          <ProcessSection props={this.props} lines={
            [
              {
                text: "Contact us for a no-cost and no-obligation consultation and get matched with a local CapCenter agent.",
                tooltip: <>Schedule a consultation<br/>via our website or call 757-334-2083</>
              },
              {
                text: "Boost your savings and get pre-approved for a CapCenter ZERO closing cost loan. You can receive an additional 1/8th off your rate just for working with CapCenter Realty!",
                tooltip: <>We recommend getting pre-approved for a mortgage before you find the right home.<ol><li>Understand your budget</li><li>Prepare your finances and lifestyle for a successful closing</li><li>Submit a strong offer before another offer is considered or accepted</li></ol>Our 1/8 rate reduction offer does not combine with our 1% Listing Fee bundle.</>
              },
              {
                text: "Get set up on your custom curated search and begin touring homes.",
                tooltip: <>We conduct a pre-offer property analysis to help assess the condition of the home to help you make more informed decisions, quickly.</>
              },
              {
                text: "Begin submitting offers.",
                tooltip: <>A strong offer usually includes a preapproval letter and earnest money deposit information.</>
              },
              {
                text: "Once an offer is accepted, you can review your mortgage commitment offer and lock your rate.",
                tooltip: <>Lock your mortgage rate after you know the subject property address.<br /><br />Lock your rate for free up to 30 days before closing.</>
              },
              {
                text: "Work with your realty and mortgage team to navigate the closing and loan underwriting process.",
                tooltip: <>The transaction typically involves contingencies such as inspections/repairs, appraisals, and financing and other processes such as chattel negotiation,  homeowner's insurance, and closing.<br /><br />A 30-day closing is typical for existing homes, but it can still feel rushed with everything else happening in life.<br /><br />This is part of the process when things get hectic and CapCenter excels.</>
              },            
              {
                text: "Complete your final walkthrough and attend closing."
              },           
              {
                text: "Enjoy your new home!"
              }
            ]}
            CTAButtons={
              <>
                <a href={`${baseRealtyUrl}/forms/request-buyers-agent`} className={'mr-4'}>
                  <button className="btn-primary mb-2" title="Contact realtor">
                    Contact realtor
                    <img src={RegisteredTrademarkWhite} className={'registered-trademark white'} />
                  </button>
                </a>
                <a href={`${baseRealtyUrl}/guides/home-buyers-guide`}>
                  <button
                    className="btn-secondary mb-2 d-none d-sm-inline"
                    title="Learn more about the home buying process"
                  >
                    Learn more about home buying
                  </button>
                  <button
                    className="btn-secondary mb-2 d-sm-none d-inline"
                    title="Learn more about the home buying process"
                  >
                    Learn more
                  </button>
                </a>
              </>
            }
          />

          <DescriptionSection props={this.props}
            figureContents={<>
            <iframe
                  src="https://www.youtube.com/embed/f5HLrly8Y5A"
                  title="How We Save You Money"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                <figcaption>Video on how CapCenter saves you time and money</figcaption>
                </>
                }
                title="Buyer's agent services"
                lines={[
                  {
                    subtitle: "Consultation to learn your needs",
                    text: <>Your goal is our mission - All of our clients go through an in-depth discovery consultation with our
                    CapCenter team so we can custom tailor a path to home ownership that aligns with your needs and
                    wants.</>
                  },
                  {
                    subtitle: "Custom curated search",
                    text: <>We design a search specific to your requests and send updates directly to your email as soon as a
                    home hits the market, so you never have to worry about missing out.</>
                  },
                  {
                    subtitle: "Coordinated tours",
                    text: <>Shop with confidence - We are a team at CapCenter and our agents work together to provide maximum
                    availability to our clients, so you can rest easy knowing we can always get you in the door.</>
                  },
                  {
                    subtitle: "Pre-offer property analysis",
                    text: <>Tour with purpose - CapCenter agents don't just open doors, we actively evaluate each property to
                    find and share conditional concerns that may not be obvious or common to most home shoppers.</>
                  },
                  {
                    subtitle: "Real-time market insights",
                    text: <>Offer like an expert - We provide local insight, collaborate to gather data, and analyze real-time
                    market conditions in each neighborhood and market we are active in. These insights are aggregated by
                    our Broker team, who analyzes transactions across all markets for your benefit.</>
                  },
                  {
                    subtitle: "Mobile-friendly document signing",
                    text: <>Empowered by technology - Our team is equipped with the latest industry technology to make
                    reviewing, signing, and uploading offers seamless and mobile friendly.</>
                  },
                  {
                    subtitle: "Negotiation expertise",
                    text: <>Maximized negotiations - Our team utilizes 26 years of industry experience and collaboration with
                    our leadership team to ensure our clients are getting the best deal possible while still protecting
                    their downside.</>
                  },
                  {
                    subtitle: "Transaction guidance",
                    text: <>Streamlined transactions - Empowered by a full support staff your CapCenter agent seamlessly guides
                    you through the entire purchase process. You will never be left wondering what's next.</>
                  },
                  {
                    subtitle: "Strong network of top-rated professionals",
                    text: <>A referral network you can trust - From mortgage, insurance, and settlement to inspectors,
                    contractors, and cleaners, CapCenter can provide a resource for anything you may need during your
                    purchase and beyond.</>
                  },
                  {
                    subtitle: "One-stop shop",
                    text: <>Bundle and save - When bundle our realty and mortgage you get the same great service with the added
                    savings of our ZERO closing cost home loans and we will take an additional 1/8th of your interest
                    rate!</>
                  }
                ]}
          />

          <NextStepsSection 
            figureContents={
              <>
                <img src={require("../../assets/capcenter-best-realtor-agency-homes-for-sale.png")} alt={"Homes for sale with best realtor agency"}/>
                <figcaption>Homes for sale with best realtor agency</figcaption>
              </>
            }
            CTAButtons={
              <>
                <a href={`${baseRealtyUrl}/forms/request-buyers-agent`} className="mr-4">
                  <button className="btn-primary mb-2" title="Speak with your local realtor">Speak with your local realtor<img src={RegisteredTrademarkWhite} className={"registered-trademark white"}/></button>
                </a>
                <a href={getUrlWithUtmQueryString(`${baseUrl}/forms/rate-alerts`)} className="mr-4">
                  <button className="btn-secondary mb-2" title="Sign up for mortgage interest rate alerts">Sign up for rate alerts</button>
                </a>
              </>
            }
          />
          
          <RefinanceSection props={this.props} />

          <FAQSection 
          questions={[
            {
              question: "I'm a first-time home buyer. Where do I start?",
              answer: <>At CapCenter, we understand how overwhelming the first-time home buying process can be. That's why we exist - to make things easier for you as one team (the added savings is a bonus). Get started with our realty team by <a href="/forms/request-buyers-agent">scheduling a consultation</a> or by calling <a href="tel:1-757-334-2083">+1 (757) 334-2083</a>. We're excited to help you find the right home.</>
            },
            {
              question: "How much money do I need to buy a house?",
              answer: <>The upfront costs vary widely on subject property, location, and borrower(s). The minimum amount you need includes costs that occur during closing such as the minimum down payment required for the loan product, government fees such as taxes, and escrows such as homeowners insurance premiums.<br/><br/>We have loan products that only require 0% to 3.5% minimum down payment. For a qualified first-time home buyer buying a $350k house in Richmond, VA, the total upfront costs are around $18,000.</>
            },
            {
              question: "Are you a full-service realty?",
              answer: <>Yes, CapCenter is a top-rated full-service realty.</>
            },
            {
              question: "Do you know my neighborhood well?",
              answer: <>CapCenter's real estate agents are local to assigned territories and execute many more deals than the average agent. Our closeness to our markets helps us understand and forecast market trends in your neighborhood. We'll provide the data and recommendations based on experience, but you make the call on how we proceed.<br/><br/>We recommend <a href={`${baseRealtyUrl}/forms/request-buyers-agent`}>scheduling a consultation</a> and speaking with your local realtor so you can see why we're rated five stars on Zillow.</>
            },
            {
              question: "Who pays for buyer's agent services?",
              answer: <>The buyer's agent services are typically paid by the seller at closing.</>
            },
            {
              question: "What are closing costs?",
              answer: <>Closing costs differ by state and county and generally refer to costs that occur at closing excluding certain items. CapCenter waives costs that are not related to the owner's equity (e.g. down payment), escrows (e.g. homeowner's insurance), or government fees (e.g. taxes) for most standard products. For details, use our <a href={getUrlWithUtmQueryString(`${baseUrl}/mortgage-calculator/`)}>mortgage calculator</a> to see typical industry fees and our waived fees in your region.</>
            },
            {
              question: "I've seen lower rates on the Internet. Why is CapCenter so popular?",
              answer: <>Many lenders artificially lower rates with hidden fees and points to try to win your business. At CapCenter, we provide low rates with low or no fees and publish our rates and fees online for full transparency.  We value honesty and transparency, and most of our clients are experienced mortgage borrowers who are tired of playing games with high, often hidden, "standard" upfront costs.<br/><br/>The main benefit to Zero Closing Cost mortgages is the flexibility. If rates fall, you can refinance with another CapCenter Zero Closing Cost mortgage for savings or move without worrying about the high sunk costs that come with a regular mortgage. The best part is, we'll let you know when it makes sense to refinance, expedite the process, and save big, again!<br/><br/><a href={getUrlWithUtmQueryString(`${baseUrl}/learning/mortgages/what-does-apr-mean`)}>Learn more how rates and APR can be misleading in the mortgage industry.</a></>
            },
            {
              question: "Does CapCenter offer down payment assistance?",
              answer: <>Freddie Mac has a database of down payment assistance programs that are available. We check that database as part of our process to let you know if you may qualify for certain program(s). However, these programs are offered by external parties such as local governments, and there are typically eligibility requirements.<br/><br/>If there's a specific program you are interested in, please let your loan consultant know.</>
            },
            {
              question: "When is the best time to buy a home?",
              answer: <>Buying a home is one of the most important financial decisions of our lives. Not only because of the significant dollar amount but because of how much impact a home has on our lifestyle, mental health, and future. With rising home prices in a volatile interest rate environment, we all wonder, when is it the best time to buy a home?<br/><br/>The answer really depends on your situation and what you're looking for. However, it's important to understand that homes are all a little different (e.g., location, style, condition, and history) and your ideal home may not be on the market when you're looking. That's why we believe the best time to buy a home is when your ideal home is on the market because that opportunity may never come again.<br/><br/>If your ideal home is on the market during a high interest rate environment, do not worry and buy with confidence. Save big with a Zero Closing Cost purchase and simply refinance with another Zero Closing Cost mortgage if rates drop, and continue to enjoy your dream lifestyle with more money in your pocket.</>
            }
          ]}/>

          <ListingsSection props={this.props} />

          <HomesForSaleSection props={{pageType: "buy"}} />
        </main>
      </div>
    </AppWrapper>;
  }
}

export default RealtyBuy