import React, { Component } from 'react';
import getTeams from '@capcenter/shared/src/api/getTeams';
import GetImage from '../functions/GetImage';
import {Tooltip} from '@capcenter/shared-legacy';

export class OurTeam extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      row1: [],
      row2: [],
      loading: true,
      profileCount: 0,
      imgsLoaded: 0,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    getTeams(this.props.team, true).then(allProfiles => {
      if (typeof allProfiles !== 'string') {
        this.setProfiles(allProfiles);
      } else {
        this.setError(allProfiles);
      }
    });
  }

  setError = error => {
    console.log(error);
  };

  setProfiles = allProfiles => {
    const shuffled = allProfiles;

    // Make array even
    if (Math.abs(shuffled.length % 2) === 1) {
      shuffled.splice(-1, 1);
    }

    const halfLength = Math.ceil(shuffled.length / 2);

    const tenCheck = halfLength < 10 ? halfLength : 10;

    const row1 = JSON.parse(JSON.stringify(shuffled)).splice(tenCheck, tenCheck);

    const row2 = JSON.parse(JSON.stringify(shuffled)).splice(0, tenCheck);

    if (this._isMounted) {
      this.setState({ profileCount: tenCheck * 2, row1, row2: row2 });
    }
  };

  updateImageCount = () => {
    const imgsLoaded = this.state.imgsLoaded + 1;

    if (this.state.loading) {
      if (this.state.profileCount === this.state.imgsLoaded) {
        this.setState({ loading: false });
      } else {
        this.setState({ imgsLoaded });
      }
    }
  };

  render() {
    const rowEven = Math.abs(this.state.row1.length % 2) === 1 ? false : true;

    const ImageRow = ({ children }) => <div style={{ height: '50%', whiteSpace: 'nowrap', textAlign: "center" }}>{children}</div>;

    const Image = ({ profile, length, index }) => {
      let firstName = profile.name;
      if (profile.name) {
        let names = profile.name.split(' ');
        firstName = names[0];
      }

      return (
        <Tooltip
          html={
            <div>
              Hi, I'm {firstName}. Welcome to CapCenter!
            </div>
          }
          position="top"
          distance={45}
          offset={150}
          interactive={false}
          arrow={true}
        >
          <img
            className={rowEven && index === length ? 'd-none d-sm-inline-block' : ''}
            src={GetImage(profile)}
            onLoad={this.updateImageCount}
            alt={"Profile picture of " + firstName}
            style={{ height: '100%' }}
          />
        </Tooltip>
      );
    };

    return (
      <div
        style={{
          height: '100%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          transition: '.5s',
          opacity: this.state.loading ? 0 : 1,
					right: '-50%',
        }}
      >
        <ImageRow>
          {this.state.row1.map((profile, i) => (
            <Image key={i} length={this.state.row1.length - 1} profile={profile} index={i} />
          ))}
        </ImageRow>

        <ImageRow>
          {this.state.row2.map((profile, i) => (
            <Image key={i} length={this.state.row2.length - 2} profile={profile} index={i} />
          ))}
        </ImageRow>
      </div>
    );
  }
}

export default OurTeam;
