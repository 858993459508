import React, { Component } from 'react';
import { Colors, Weights, AppList, AppIcon, baseUrl } from '@capcenter/shared';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './RatesCarousel.scss';
import numeral from 'numeral';
import moment from "moment";
import queryString from 'qs';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getUrlWithUtmQueryString } from '@capcenter/shared-legacy';
import { basePricingScenario } from '@capcenter/quote';

const CarouselItem = props => (
	<div className="text-center  col-xs-12 col-sm-9 col-lg-8 col-xl-7" style={{ margin: '0 auto' }}>
		<span style={{ fontSize: '140%' }}>
			<div style={{ display: 'inline-block', width: 30, height: 30, marginRight: 10, verticalAlign: 'middle' }}>
				<AppIcon app={AppList.todaysRates} />
			</div>
			{props.title}
		</span>
		<br />
		<div className="d-flex row justify-content-around" style={{ padding: '5px 0' }}>
			{(Array.isArray(props.rates) && props.rates.length > 0) ? (
				props.rates.map((rate, i) => {

					const missingInfoString = '--'

					if (rate.quote === undefined) {
						rate.quote = {
							Apr: missingInfoString,
							BaseRate: missingInfoString,
							Points: missingInfoString,
							RateID: missingInfoString,
						};
					}

					const baseRate = (rate.quote.BaseRate === missingInfoString ? rate.quote.BaseRate : numeral(rate.quote.BaseRate).format('0.000')) + '%'
					const apr = (rate.quote.Apr === missingInfoString ? rate.quote.Apr : numeral(rate.quote.Apr).format('0.000')) + '%'
					const points = (rate.quote.Points === missingInfoString ? rate.quote.Points : numeral(rate.quote.Points).format('0.000')) + 'pts'

					return (
						<div key={i} className='col-lg-3 col-6 position-relative px-1 py-3'>
							<div className="flex align-items-center font-weight-bold whitespace-nowrap position-relative mb-0">
								{(rate.loanType === 'Fixed30') ? "30 Year Fixed" : (rate.loanType === 'Fixed15') ? "15 Year Fixed" : "ARM 7/6"}
								<OverlayTrigger
									key={rate.loanType}
									placement={'top'}
									overlay={
										<Tooltip id={`tooltip-${rate.loanType}`} className='carouselRateTooltip'>
											{(rate.loanType !== 'ARM') ? <span><strong>CapCenter Tip</strong><br /><span>Make sure you compare lender and non-lender closing fees when comparing rates. Apply to get a free quote with CapCenter.</span></span>
												: <span><strong>Adjustable Rate Mortgage</strong><br /><span>Interest rate is fixed for 7 years and will adjust every 6 months after the fixed period.</span></span>}
										</Tooltip>
									}
								>
									<i className='cc-btn__icon fas fa-info-circle font--sm ml-2' data-popper-arrow />
								</OverlayTrigger>
							</div>
							<span style={{ fontSize: '120%' }}>{baseRate}</span>
							<hr style={{ margin: 'auto', width: '70%' }} />
							<span style={{ fontSize: '80%' }}>{apr}&nbsp;APR</span>
							<br />
							<span style={{ fontSize: '16px', color: '#C2C2C2' }}>{points}</span>
						</div>
					)
				})
			) : (
				<>
					<div>
						_.___%
						<hr style={{ margin: '2.5px 0' }} />
						_.___ pts
						<br />
						_.___% APR
					</div>

					<div>
						_.___%
						<hr style={{ margin: '2.5px 0' }} />
						_.___ pts
						<br />
						_.___% APR
					</div>

					<div>
						_.___%
						<hr style={{ margin: '2.5px 0' }} />
						_.___ pts
						<br />
						_.___% APR
					</div>

					<div>
						_.___%
						<hr style={{ margin: '2.5px 0' }} />
						_.___ pts
						<br />
						_.___% APR
					</div>
				</>
			)}
		</div>

		<div className="mb-4" style={{ paddingTop: '8px' }}>
			<span style={{ fontWeight: 'lighter' }}>Comparing Loan Terms in Richmond, VA</span>
			<br />
			<small style={{ fontWeight: 'lighter' }}>
				Assumes a ${numeral(props.defaultStateParam.LoanAmount).format('0,0')} conventional loan
				<br />
			</small>
			<a href={getUrlWithUtmQueryString(props.link + "?LoanPurpose=" + props.loanPurpose)}>{props.linkText} &#8250;</a>
		</div>
	</div>
);

export class RatesCarousel extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			rates: [],
			refiRates: [],
			refiLoading: true,
			ratesLastUpdatedTS: undefined,
			defaultStateParamPurchase: {},
			defaultStateParamRefinance: {},
		};
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	async componentDidMount() {
		this._isMounted = true;

		const defaultStateParamPurchase = await basePricingScenario('RatesDefaultStates', 'Purchase');
		this.setState({defaultStateParamPurchase: defaultStateParamPurchase})
		const defaultStateParamRefinance = await basePricingScenario('RatesDefaultStates', 'Refinance');
		this.setState({defaultStateParamRefinance: defaultStateParamRefinance})

		const url = baseUrl + '/quoteApi/v1/getDefaultGetQuoteRates';
		let mongoDefaultStateRates = null;

		try {
			let response = await fetch(url);
			mongoDefaultStateRates = await response.json();		 
		  } catch (err) {
			console.log(err);
	  
			return 'Unexpected States Database Error. Please try again later.';
		  }

		if (mongoDefaultStateRates !== undefined || mongoDefaultStateRates !== null) {
			this.setState({ ratesLastUpdatedTS: mongoDefaultStateRates.OfficialLastUpdatedTS })
		}

		if (mongoDefaultStateRates !== undefined) {

			const refiRatesFixed30RatesLength = mongoDefaultStateRates.Products.find(product => product.ProductInfo.terms === "Terms_360" && product.ProductInfo.loanType === "Fixed" && product.ProductInfo.loanPurpose === "Refinance")?.Rates.length;
			const refiRatesFixed15RatesLength = mongoDefaultStateRates.Products.find(product => product.ProductInfo.terms === "Terms_180" && product.ProductInfo.loanType === "Fixed" && product.ProductInfo.loanPurpose === "Refinance")?.Rates.length;

			const fixed30YearRefinanceQuoteNoPoints = mongoDefaultStateRates.Products.find(product => product.ProductInfo.terms === "Terms_360" && product.ProductInfo.loanType === "Fixed" && product.ProductInfo.loanPurpose === "Refinance")?.Rates[0];
			const fixed30YearRefinanceQuoteWithPoints = mongoDefaultStateRates.Products.find(product => product.ProductInfo.terms === "Terms_360" && product.ProductInfo.loanType === "Fixed" && product.ProductInfo.loanPurpose === "Refinance")?.Rates[refiRatesFixed30RatesLength-1];
			const fixed15YearRefinanceQuoteNoPoints = mongoDefaultStateRates.Products.find(product => product.ProductInfo.terms === "Terms_180" && product.ProductInfo.loanType === "Fixed" && product.ProductInfo.loanPurpose === "Refinance")?.Rates[0];
			const fixed15YearRefinanceQuoteWithPoints = mongoDefaultStateRates.Products.find(product => product.ProductInfo.terms === "Terms_180" && product.ProductInfo.loanType === "Fixed" && product.ProductInfo.loanPurpose === "Refinance")?.Rates[refiRatesFixed15RatesLength-1];

			this.setState({
				refiRates: [
					{ quote: fixed30YearRefinanceQuoteNoPoints, loanType: 'Fixed30' },
					{ quote: fixed30YearRefinanceQuoteWithPoints, loanType: 'Fixed30' },
					{ quote: fixed15YearRefinanceQuoteNoPoints, loanType: 'Fixed15' },
					{ quote: fixed15YearRefinanceQuoteWithPoints, loanType: 'Fixed15' }]
			})
		}
		else {
			this.setError("Could not retrieve rates");
		}

		const purchaseRatesFixed30RatesLength = mongoDefaultStateRates.Products.find(product => product.ProductInfo.terms === "Terms_360" && product.ProductInfo.loanType === "Fixed" && product.ProductInfo.loanPurpose === "Purchase")?.Rates.length;
		const purchaseRatesFixed15RatesLength = mongoDefaultStateRates.Products.find(product => product.ProductInfo.terms === "Terms_180" && product.ProductInfo.loanType === "Fixed" && product.ProductInfo.loanPurpose === "Purchase")?.Rates.length;


		const fixed30YearPurchaseQuoteNoPoints = mongoDefaultStateRates.Products.find(product => product.ProductInfo.terms === "Terms_360" && product.ProductInfo.loanType === "Fixed" && product.ProductInfo.loanPurpose === "Purchase")?.Rates[0];
		const fixed30YearPurchaseQuoteWithPoints = mongoDefaultStateRates.Products.find(product => product.ProductInfo.terms === "Terms_360" && product.ProductInfo.loanType === "Fixed" && product.ProductInfo.loanPurpose === "Purchase")?.Rates[purchaseRatesFixed30RatesLength-1];
		const fixed15YearPurchaseQuoteNoPoints = mongoDefaultStateRates.Products.find(product => product.ProductInfo.terms === "Terms_180" && product.ProductInfo.loanType === "Fixed" && product.ProductInfo.loanPurpose === "Purchase")?.Rates[0];
		const fixed15YearPurchaseQuoteWithPoints = mongoDefaultStateRates.Products.find(product => product.ProductInfo.terms === "Terms_180" && product.ProductInfo.loanType === "Fixed" && product.ProductInfo.loanPurpose === "Purchase")?.Rates[purchaseRatesFixed15RatesLength-1];

		if (mongoDefaultStateRates !== undefined) {
			this.setState({
				rates: [
					{ quote: fixed30YearPurchaseQuoteNoPoints, loanType: 'Fixed30' },
					{ quote: fixed30YearPurchaseQuoteWithPoints, loanType: 'Fixed30' },
					{ quote: fixed15YearPurchaseQuoteNoPoints, loanType: 'Fixed15' },
					{ quote: fixed15YearPurchaseQuoteWithPoints, loanType: 'Fixed15' }]
			})
		}
		else {
			this.setError("Could not retrieve rates");
		}

	}

	setError = error => {
		console.log(error);
		this.setState({ error: error });
	};

	render() {
		return (
			<>
				<div
					className="carousel-parent py-3"
					style={{
						height: '100%',
						width: '100%',
						background: '#0C2C48',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						color: Colors.white100,
						fontSize: '130%',
						fontWeight: Weights.bold,
						textAlign: 'center',
					}}
				>
					<div style={{ width: '100%' }}>
						<Carousel
							selectedItem={this.props.selectedItem ? this.props.selectedItem : 0}
							showThumbs={false}
							showStatus={false}
							showArrows={false}
						>
							<CarouselItem loanPurpose="Purchase" rates={this.state.rates} title="Purchase Rates" defaultStateParam={this.state.defaultStateParamPurchase} {...this.props} />
							<CarouselItem loanPurpose="Refinance" rates={this.state.refiRates} title="Refinance Rates" defaultStateParam={this.state.defaultStateParamRefinance} {...this.props} />
						</Carousel>
						<small className="carousel-small" style={{ color: '#BCCACB' }}>
							Rates last updated {(this.state.rates?.length && this.state.ratesLastUpdatedTS) ? moment(this.state.ratesLastUpdatedTS).format("MM/DD/YYYY, h:mm A") : "..."}
							<br />
						</small>
					</div>
				</div>
			</>
		);
	}
}

export default RatesCarousel;
