import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/remote-config";
import { Flag, initializeApp, RemoteConfigFetcher } from '@capcenter/firebase-service';
import {
    sessionLogin
} from "@capcenter/shared-active-directory";
import { initializeCache } from "../api/firebase";

export const firebaseAuth = initializeApp(window.location.hostname);

export async function handleCredentialResponse(response: {credential: string}) {

    await firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    // Build Firebase credential with the Google ID token.
    const googleIdToken = response.credential;
    const credential = firebase.auth.GoogleAuthProvider.credential(googleIdToken);

    // Sign in with credential from the Google user.
    await firebaseAuth.signInWithCredential(credential);

    const currentUser = firebaseAuth.currentUser;
    if (currentUser) {
        const firebaseToken = await currentUser.getIdToken(true);

        if (firebaseToken && currentUser.providerData?.length && currentUser.providerData.length > 0) {
            await sessionLogin(firebaseToken);
        }
    }
};


firebaseAuth.onAuthStateChanged((user: any) => {
    if (user) {
      firebaseAuth.updateCurrentUser(user);
      initializeCache()
    }
  });

export const remoteConfig: RemoteConfigFetcher = new RemoteConfigFetcher(
    {
        minimumFetchIntervalMillis: 360000,
        fetchTimeoutMillis: 10000,
    },
    {
        hideHelpScoutChat: false,
        showGoogleSignInModal: false
    },
);

const getBooleanFeatureFlag = async (flagName: string) => {
    await remoteConfig.fetchAndActivate()
    const flag: Flag | undefined = await remoteConfig.getFeatureFlagObject(flagName);

    if (!flag) return false;
    return flag as any;
};
 
export { getBooleanFeatureFlag }
