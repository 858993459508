import React, { Component } from 'react';
import {mapQuoteWidgetFromFields, QuoteLoader, basePricingScenario} from '@capcenter/quote'
import BuySavingsTooltipFields from './BuySavingsTooltipFields'
import BuySavingsComponentFields from './BuySavingsComponentFields'
import numeral from "numeral";
import {Colors, getUrlWithUtmQueryString} from "@capcenter/shared";
import queryString from "qs";

class BuySavings extends Component {

	async componentDidMount() {
		const defaultStateParam = await basePricingScenario('CalculatorDefaultStates', 'Purchase');
		
		const initialValue = defaultStateParam.PurchasePrice;
		const buySavingsValue = initialValue;
		const purchasePrice = buySavingsValue;
		const downPayment = purchasePrice * .20;
		const loanAmount = purchasePrice - downPayment;
		
		this.setState({
			sliderValue: buySavingsValue,
			initialPurchasePrice: purchasePrice,
			initialLoanAmount: loanAmount,
			initialDownPayment: downPayment,
			defaultStateParam: defaultStateParam,})
	
	}

	constructor(props) {
		super(props);

		const initialValue = 425000;
		const buySavingsValue = props.buySavings?.value ?? initialValue;
		const purchasePrice = buySavingsValue;
		const downPayment = purchasePrice * .20;
		const loanAmount = purchasePrice - downPayment;

		this.state = {
			sliderValue: buySavingsValue,
			initialPurchasePrice: purchasePrice,
			initialLoanAmount: loanAmount,
			initialDownPayment: downPayment,
		}
	}

	setSliderValue = (value) => {
		this.setState({ sliderValue: value });
	};

	render(){

    return (
			<>
				{this.state.defaultStateParam && <QuoteLoader defaultState quoteWidget={{
					PurchasePrice: this.state.initialPurchasePrice,
					LoanAmount: this.state.initialLoanAmount,
					DownPayment: this.state.initialDownPayment,
				}}>
					{(props) =>

					this.props.tooltip ?
						<BuySavingsTooltipFields
							LoanPurpose="Purchase"
							link={getUrlWithUtmQueryString(this.props.thisPage.path + queryString.stringify({
								quoteWidget: mapQuoteWidgetFromFields(props.fields)
							})) + "#savings"}
							sliderValue={this.state.sliderValue}
							setSliderValue={this.setSliderValue}
							tabClick={this.props.tabClick}
							tooltipOpen={this.props.tooltipOpen}
							setOpen={this.props.setOpen}
							savings={props.quotes.length !==0 && props.quotes[0]?.PaidAtClosing?.TotalSavings ? "$" + numeral(props.quotes[0]?.PaidAtClosing?.TotalSavings).format("0,0") : "$_,___"}
							text="Zero Closing Costs"
							label="Buy for"
							value={props.fields.PurchasePrice ? "$" + numeral(props.fields.PurchasePrice).format("0.[0]a"): "$___k"}
							first
							{...props} {...this.props} /> :

						<BuySavingsComponentFields
							LoanPurpose="Purchase"
							text="Buy for"
							sliderValue={this.state.sliderValue}
							setSliderValue={this.setSliderValue}
							savings={props.quotes.length !== 0 && props.quotes[0]?.PaidAtClosing?.TotalSavings}
							offset={0}
							table={[
								{
									text: "CapCenter Cash to Close",
									value: props.quotes.length !== 0 && props.quotes[0]?.PaidAtClosing?.Total.CapcenterFee,
									color: Colors.primary
								},{
									text: "Industry Avg. Cash to Close",
									value: props.quotes.length !== 0 && props.quotes[0]?.PaidAtClosing?.Total.CompetitorFee,
								},{
									text: "Savings with Zero Closing Costs",
									value: props.quotes.length !== 0 && props.quotes[0]?.PaidAtClosing?.TotalSavings,
									color: Colors.green500,
									total: true,
								}

							]}
							desc={"Example: " + numeral(20).format('0') + "% Down, Primary Residence"}
							link={getUrlWithUtmQueryString("/mortgage-calculator/purchase?" + queryString.stringify({
								quoteWidget: mapQuoteWidgetFromFields(props.fields)
							})) + "#savings"}
							{...props} {...this.props} />
					}
				</QuoteLoader>}
			</>
		)
	}
}

export default BuySavings;