import React from 'react';
import {AppWrapper, baseRealtyUrl, baseUrl} from "@capcenter/shared";
import '../Realty.scss';
import {AddressSearch, getUrlWithUtmQueryString} from "@capcenter/shared-legacy";
import RegisteredTrademark from "../../assets/registered-trademark.svg";
import RegisteredTrademarkWhite from '../../assets/registered-trademark-white.svg';

// Core Page Sections
import FAQSection from '../sections/FAQ';
import DataSection from '../sections/Data';
import DescriptionSection from '../sections/Description';
import ProcessSection from '../sections/Process';
import ReviewsSection from '../sections/Reviews';
import SavingsSection from '../sections/Savings';
import NextStepsSection from '../sections/NextSteps';
import ListingsSection from '../sections/Listings';
import HomesForSaleSection from '../sections/HomesForSale';

class HomeValue extends React.Component {
  render() {
    return <AppWrapper app="realtyHomeValue" noHeader>
      <div role="document">
        <main className="realty">
          <section className="section-search-hero">
            <div className="container-fluid">
              <div className="row justify-content-between">
                <article className="search-group buy">
                  <header>
                    <hr className="hero-rule"/>
                    <h1 data-aos="flip-left">
                      How much is my home worth?
                    </h1>
                  </header>
                  <div className="tab-container">
                    <div className="tab"><a href="/best-realtor-agency-buy-home" className={'d-none d-sm-inline-block tab-buy-link'}>Buy</a><a href="/best-realtor-agency-buy-home" className={'d-inline-block d-sm-none tab-buy-link'}>Buy</a></div>
                    <div className="tab"><a href="/best-realtor-agency-sell-home" className={'d-none d-sm-inline-block tab-sell-link'}>Sell</a><a href="/best-realtor-agency-sell-home" className={'d-inline-block d-sm-none tab-sell-link'}>Sell</a></div>
                    <div className="tab"><a href="/get-preapproved-for-a-home-loan" className={'d-none d-sm-inline-block tab-preapproval-link'}>Pre-approval</a><a href="/get-preapproved-for-a-home-loan" className={'d-inline-block d-sm-none tab-preapproval-link'}>Pre-approval</a></div>
                    <div className="tab active"><a href="/how-much-is-my-home-worth" className={'d-none d-sm-inline-block tab-homevalue-link'}>Home Value</a></div>
                  </div>
                  <div className="search-bar">
                    <AddressSearch
                      setAddress={(address) => {
                        if (address) {
                          const urlAddress = address.toLowerCase().replace(/\s/g, '-').replace(/,/g, '');
                          window.location.href = `${baseRealtyUrl}/home-sale-estimate/valuation?location=${urlAddress}`;
                        }
                      }}
                      setAllData={(data) => {
                      }}
                      placeholder={'Address of home to estimate value'}
                      includeZip={true}
                      noPadding={true}
                      log={false}
                    />
                  </div>
                </article>
                <article className="hero-right sell">
                </article>
              </div>
            </div>
          </section>

          <SavingsSection />

          <DataSection />

          <ReviewsSection />

          <ProcessSection props={this.props} lines={
            [
              {
                text: <>Contact us to get matched with your local CapCenter realtor<img src={RegisteredTrademark} className={"registered-trademark"}/></>,
                tooltip: <>Schedule a consultation via our website <br/>or call 757-334-2083</>
              },
              {
                text: "Schedule an initial listing consultation at your home",
              },
              {
                text: "CapCenter provides home preparation advice and home valuation estimate with market data and historic trends",
                tooltip: "Our realty team is in tune with our markets and understands what works and doesn't work to help you sell your home quickly for more in your neighborhood."
              },
              {
                text: "CapCenter takes professional photography of your home"
              },           
              {
                text: "CapCenter markets your home on the relevant MLSs, websites, flyers, open houses, email campaigns, and/or social media."
              },
              {
                text: "View or approve tours on mobile app"
              }, 
              {
                text: "Review and accept offer(s)",
                tooltip: "A strong offer usually includes a preapproval letter and earnest money deposit information."
              },
              {
                text: "Complete real estate sale transaction",
                tooltip: <>
                The transaction typically involves contingencies such as inspections/repairs, appraisals, and financing and other processes such as chattel negotiation,  homeowner's insurance, and closing.
                <br/><br/>A 30-day closing is typical for existing homes, but it can still feel rushed with everything else happening in life.
                <br/><br/>This is part of the process when things get hectic and CapCenter excels.
                </>
              },            
              {
                text: "Receive funds!",
                tooltip: "Please be aware that there may be tax implications on these funds based on your unique situation."
              }
            ]}
            CTAButtons={
              <>
                <a href={`${baseRealtyUrl}/forms/request-listing-agent`} className={"mr-4"}>
                  <button className="btn-primary mb-2 d-none d-sm-inline" title="Contact your local realtor">Contact your local realtor<img src={RegisteredTrademarkWhite} className={"registered-trademark"}/></button>
                  <button className="btn-primary mb-2 d-sm-none d-inline" title="Contact your local realtor">Contact realtor<img src={RegisteredTrademarkWhite} className={"registered-trademark"}/></button>
                </a>
                <a href={`${baseRealtyUrl}/guides/home-sellers-guide`}>
                  <button className="btn-secondary mb-2 d-none d-sm-inline" title="Learn more about the home selling process">Learn more about home selling</button>
                  <button className="btn-secondary mb-2 d-sm-none d-inline" title="Learn more about the home selling process">Learn more</button>
                </a>
              </>
            }
          />

          <DescriptionSection props={this.props}
            figureContents={
              <>
              <img src={require("../../assets/scheduled-listing-consultation.png")} alt="Best realtor agency tour home"/>
              <figcaption>Take the next step towards homeownership</figcaption>
              </>
            }
            title="Full-service realty listings"
            lines={
              [
                {
                  subtitle: "Consultation to learn your needs",
                  text: <>We learn about your needs, wants, and constraints to <strong>understand your perspective and align with your vision</strong></>
                },
                {
                  subtitle: "Curated agent matching",
                  text: <>We <strong>match you with the best-fit agent</strong> that has the most insight in your neighborhood and best
                  meets your needs. You can also request a specific team member.</>
                },
                {
                  subtitle: "Professional home value estimate based on real-time insights",
                  text: <>We professionally value your home using our <strong>proprietary comparative market analysis
                  tool that incorporates real-time market data</strong>.</>
                },
                {
                  subtitle: "Home preparation to maximize value",
                  text: <>We conduct a walkthrough and provide home preparation tips. <strong>We also offer home
                  staging services and accompanied showings when beneficial</strong>.</>
                },
                {
                  subtitle: "Official and unofficial listing services",
                  text: <><strong>We list your home on the "official" MLS and various home search engines</strong> with your
                  approved pricing, property description, and professional photography for increased visibility.</>
                },
                {
                  subtitle: "Omni-channel marketing",
                  text: <>We market your home using our established networks and marketing channels such as
                  the official MLS, listing flyers, email marketing, social media, open houses, and our home search tool
                  with professional-grade photography.</>
                },
                {
                  subtitle: "Coordinated tours",
                  text: <>We use a <strong>mobile app to coordinate tours</strong> that you can view, approve, or reject.</>
                },
                {
                  subtitle: "Mobile-friendly document signing",
                  text: <>With our guidance and your direction, we can prepare documents such as offers, sale
                  agreements, and contract addendums that you can <strong>review and sign via mobile device</strong>.</>
                },
                {
                  subtitle: "Transaction guidance and negotiation expertise",
                  text: <>We guide you through the sale agreement process and <strong>provide insights to help you
                  maximize value with less stress</strong>. We share our experience on negotiated items to help you understand
                  whether requests are typical or aggressive.</>
                },
                {
                  subtitle: "Strong network of top-rated professionals",
                  text: <>We have a strong network of top-rated contractors to address repair requests from
                  home inspections.</>
                },
                {
                  subtitle: "One-stop shop",
                  text: <>When you bundle our services such as realty, mortgage, and insurance, <strong>we provide
                  even more convenience and savings</strong>. At CapCenter, we don't believe full service equates to full
                  fee.</>
                },
                // {
                //   subtitle: "",
                //   text: <></>
                // }
              ]}
          />

          <NextStepsSection 
            figureContents={
              <>
                <img src={require("../../assets/capcenter-best-realtor-sold-home.png")} alt="Sell your home"/>
                <figcaption>Take the next step towards selling your home</figcaption>
              </>
            }
            CTAButtons={
              <>
                <a href={`${baseRealtyUrl}/forms/request-listing-agent`} className="mr-4">
                  <button className="btn-primary mb-2" title="Speak with your local realtor">Speak with your local realtor<img src={RegisteredTrademarkWhite} className={"registered-trademark"}/></button>
                </a>
                <a href={`${baseRealtyUrl}/home-sale-estimate/valuation`} className="mr-4">
                  <button className="btn-secondary mb-2" title="What's my home is worth?">Estimate home value</button>
                </a>
              </>
            }
          />

          <FAQSection 
            questions={[
              {
                question: "How much is my home worth?",
                answer: <>CapCenter has an <a href="/home-sale-estimate/valuation">automated home value estimation tool</a> that estimates the value of your home using publicly reported information. However, it is very difficult to value your home with just public data because of missing or inaccurate information such as unreported renovations or damages.<br/><br/>Please <a href={`${baseRealtyUrl}/forms/request-listing-agent`}>consult our listing team</a> by <a href="/forms/request-listing-agent">scheduling a consultation</a> or by calling <a href="tel:1-757-334-2083">+1 (757) 334-2083</a> for a more accurate home valuation .</>
              },
              {
                question: "How does your 1% Listing Fee work?",
                answer: <>You can sell your home with our standard listing fee of 1.5%, which is half the industry's traditional listing fee of 3%. When you purchase your next home with CapCenter within a year, we reimburse our 0.5% of your listing fee, effectively making the listing fee 1%.</>
              },
              {
                question: "Are you a full-service realty?",
                answer: <>Yes, CapCenter is a top-rated full-service realty.<br/><br/>Other firms or listing agents may offer additional services with extra fees. Please <a href={`${baseRealtyUrl}/forms/request-listing-agent`}>consult our listing team</a> regarding matching non-standard services.</>
              },
              {
                question: " Do you know my neighborhood well?",
                answer: <>CapCenter's real estate agents are local to assigned territories and execute many more deals than the average agent. Our closeness to our markets helps us understand and forecast market trends in your neighborhood. We'll provide the data and recommendations based on experience, but you make the call on how we should proceed with your home.<br/><br/>We recommend <a href={`${baseRealtyUrl}/forms/request-listing-agent`}>scheduling a consultation</a> and speaking with your local realtor<img src={RegisteredTrademarkWhite} className={"registered-trademark"}/> so you can see why we're rated five stars on Zillow.</>
              },
              {
                question: "How quickly can I sell my home?",
                answer: <>Our average time on market was 12.6 days and 82% of homes sold at or above listing price based on CVR MLS (Central Virginia), which is one of our MLSs with the most reliable data. The closing timeline, which varies by contract, is not included as part of average days on market. Please <a href={`${baseRealtyUrl}/forms/request-listing-agent`}>consult our listing team</a> for an estimate on your home based on your neighborhood's market data.</>
              },
              {
                question: "What are closing costs?",
                answer: <>Closing costs differ by state and county and generally refer to costs that occur at closing excluding certain items.<br/><br/>For purchasing a home, CapCenter waives costs that are not related to the owner's equity (e.g. down payment), escrows (e.g. homeowner's insurance), or government fees (e.g. taxes) for most standard products. For details, use our <a href={getUrlWithUtmQueryString(`${baseUrl}/mortgage-calculator/purchase`)}>mortgage calculator</a> to see typical industry fees and our waived fees in your region.<br/><br/>For selling a home, CapCenter waives the listing fee when you buy, sell, and finance within a year. For details, use our <a href={`${baseRealtyUrl}/home-sale-estimate/valuation/`}>home sale calculator</a></>
              }
            ]}
          />

          <ListingsSection pageType="sell"/>

          <HomesForSaleSection pageType="sell"/>
        </main>
      </div>
    </AppWrapper>;
  }
}

export default HomeValue