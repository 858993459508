import React, { Component } from 'react';
import { Colors, Weights } from '@capcenter/shared';
import { getProductCombinations } from '@capcenter/shared';
import getQuoteRates from '../../../Quote/src/components/QuoteRatesLoader/getQuoteRates';
import queryString from 'qs';
import { Icon } from '@capcenter/shared-legacy';
import mapQuoteRates from '@capcenter/quote/src/components/QuoteRatesLoader/MapQuoteRatesV2';

export class LoanOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    getProductCombinations().then(allProducts => {
      //this.setState({allProducts})
      //console.log(allProducts)
      if (Array.isArray(allProducts)) {
        const ThirtyYr = allProducts.filter(row => row.LoanTerm === '30-Year Term');
        const ThirtyYrFixed = ThirtyYr.filter(row => row.LoanType === 'Fixed');
        const ThirtyYrFHA = ThirtyYr.filter(row => row.LoanProgram === 'FHA');
        const ThirtyYrVA = ThirtyYr.filter(row => row.LoanProgram === 'VA');
        const ThirtyYrJumbo = ThirtyYr.filter(row => row.LoanAmount === 'Jumbo');
        const ThirtyYrARM = ThirtyYr.filter(row => row.LoanType === '5/6 ARM' || row.LoanType === '7/6 ARM' || row.LoanType === '10/6 ARM');

        const TwentyYr = allProducts.filter(row => row.LoanTerm === '20-Year Term');
        const TwentyYrFixed = TwentyYr.filter(row => row.LoanType === 'Fixed');
        const TwentyYrFHA = TwentyYr.filter(row => row.LoanProgram === 'FHA');
        const TwentyYrVA = TwentyYr.filter(row => row.LoanProgram === 'VA');
        const TwentyYrJumbo = TwentyYr.filter(row => row.LoanAmount === 'Jumbo');
        const TwentyYrARM = TwentyYr.filter(row => row.LoanType === '5/6 ARM' || row.LoanType === '7/6 ARM' || row.LoanType === '10/6 ARM');

        const FifteenYr = allProducts.filter(row => row.LoanTerm === '15-Year Term');
        const FifteenYrFixed = FifteenYr.filter(row => row.LoanType === 'Fixed');
        const FifteenYrFHA = FifteenYr.filter(row => row.LoanProgram === 'FHA');
        const FifteenYrVA = FifteenYr.filter(row => row.LoanProgram === 'VA');
        const FifteenYrJumbo = FifteenYr.filter(row => row.LoanAmount === 'Jumbo');
        const FifteenYrARM = FifteenYr.filter(row => row.LoanType === '5/6 ARM' || row.LoanType === '7/6 ARM' || row.LoanType === '10/6 ARM');

        const TenYr = allProducts.filter(row => row.LoanTerm === '10-Year Term');
        const TenYrFixed = TenYr.filter(row => row.LoanType === 'Fixed');
        const TenYrFHA = TenYr.filter(row => row.LoanProgram === 'FHA');
        const TenYrVA = TenYr.filter(row => row.LoanProgram === 'VA');
        const TenYrJumbo = TenYr.filter(row => row.LoanAmount === 'Jumbo');
        const TenYrARM = TenYr.filter(row => row.LoanType === '5/6 ARM' || row.LoanType === '7/6 ARM' || row.LoanType === '10/6 ARM');

        const Fixed =
          ThirtyYrFixed.length > 0 || TwentyYrFixed.length > 0 || FifteenYrFixed.length > 0 || TenYrFixed.length > 0;
        const FHA = ThirtyYrFHA.length > 0 || TwentyYrFHA.length > 0 || FifteenYrFHA.length > 0 || TenYrFHA.length > 0;
        const VA = ThirtyYrVA.length > 0 || TwentyYrVA.length > 0 || FifteenYrVA.length > 0 || TenYrVA.length > 0;
        const Jumbo =
          ThirtyYrJumbo.length > 0 || TwentyYrJumbo.length > 0 || FifteenYrJumbo.length > 0 || TenYrJumbo.length > 0;
        const ARM = ThirtyYrARM.length > 0 || TwentyYrARM.length > 0 || FifteenYrARM.length > 0 || TenYrARM.length > 0;

        this.setState({
          ThirtyYr: ThirtyYr.length > 0,
          ThirtyYrFixed: ThirtyYrFixed.length > 0,
          ThirtyYrFHA: ThirtyYrFHA.length > 0,
          ThirtyYrVA: ThirtyYrVA.length > 0,
          ThirtyYrJumbo: ThirtyYrJumbo.length > 0,
          ThirtyYrARM: ThirtyYrARM.length > 0,
          TwentyYr: TwentyYr.length > 0,
          TwentyYrFixed: TwentyYrFixed.length > 0,
          TwentyYrFHA: TwentyYrFHA.length > 0,
          TwentyYrVA: TwentyYrVA.length > 0,
          TwentyYrJumbo: TwentyYrJumbo.length > 0,
          TwentyYrARM: TwentyYrARM.length > 0,
          FifteenYr: FifteenYr.length > 0,
          FifteenYrFixed: FifteenYrFixed.length > 0,
          FifteenYrFHA: FifteenYrFHA.length > 0,
          FifteenYrVA: FifteenYrVA.length > 0,
          FifteenYrJumbo: FifteenYrJumbo.length > 0,
          FifteenYrARM: FifteenYrARM.length > 0,
          TenYr: TenYr.length > 0,
          TenYrFixed: TenYrFixed.length > 0,
          TenYrFHA: TenYrFHA.length > 0,
          TenYrVA: TenYrVA.length > 0,
          TenYrJumbo: TenYrJumbo.length > 0,
          TenYrARM: TenYrARM.length > 0,
          Fixed,
          FHA,
          VA,
          Jumbo,
          ARM,
        });
      }
    }).then(async () => {

    const fields = {
      AnnualIncome: "100000",
      CountyID: 123,
      FirstTimeHomeBuyer: "true",
      LoanAmount: "340000",
      LoanAmountType: "Conforming",
      LoanProgram: "Conventional",
      LoanPurpose: "Purchase",
      LoanTerm: "All",
      LoanType: "Fixed",
      NeedRealtyTeam: "Yes",
      PropertyLocation: "Richmond, VA, USA",
      State: "VA",
      ZipCode: "23249"
    }

    const newMapped = mapQuoteRates(fields)

    const county123Rates = await getQuoteRates(newMapped)

		if (county123Rates !== undefined) {

      const ThirtyYrRates = county123Rates.Products.filter(row => row.LoanTerm === '30-Year Term');
      const ThirtyYrFixedRates = ThirtyYrRates.filter(row => row.LoanType === 'Fixed');
      const ThirtyYrFHARates = ThirtyYrRates.filter(row => row.LoanProgram === 'FHA');
      const ThirtyYrVARates = ThirtyYrRates.filter(row => row.LoanProgram === 'VA');
      const ThirtyYrJumboRates = ThirtyYrRates.filter(row => row.LoanAmount === 'Jumbo');
      const ThirtyYrARMRates = ThirtyYrRates.filter(row => row.LoanType === '5/6 ARM' || row.LoanType === '7/6 ARM' || row.LoanType === '10/6 ARM');

      const TwentyYrRates = county123Rates.Products.filter(row => row.LoanTerm === '20-Year Term');
      const TwentyYrFixedRates = TwentyYrRates.filter(row => row.LoanType === 'Fixed');
      const TwentyYrFHARates = TwentyYrRates.filter(row => row.LoanProgram === 'FHA');
      const TwentyYrVARates = TwentyYrRates.filter(row => row.LoanProgram === 'VA');
      const TwentyYrJumboRates = TwentyYrRates.filter(row => row.LoanAmount === 'Jumbo');
      const TwentyYrARMRates = TwentyYrRates.filter(row => row.LoanType === '5/6 ARM' || row.LoanType === '7/6 ARM' || row.LoanType === '10/6 ARM');

      const FifteenYrRates = county123Rates.Products.filter(row => row.LoanTerm === '15-Year Term');
      const FifteenYrFixedRates = FifteenYrRates.filter(row => row.LoanType === 'Fixed');
      const FifteenYrFHARates = FifteenYrRates.filter(row => row.LoanProgram === 'FHA');
      const FifteenYrVARates = FifteenYrRates.filter(row => row.LoanProgram === 'VA');
      const FifteenYrJumboRates = FifteenYrRates.filter(row => row.LoanAmount === 'Jumbo');
      const FifteenYrARMRates = FifteenYrRates.filter(row => row.LoanType === '5/6 ARM' || row.LoanType === '7/6 ARM' || row.LoanType === '10/6 ARM');

      const TenYrRates = county123Rates.Products.filter(row => row.LoanTerm === '10-Year Term');
      const TenYrFixedRates = TenYrRates.filter(row => row.LoanType === 'Fixed');
      const TenYrFHARates = TenYrRates.filter(row => row.LoanProgram === 'FHA');
      const TenYrVARates = TenYrRates.filter(row => row.LoanProgram === 'VA');
      const TenYrJumboRates = TenYrRates.filter(row => row.LoanAmount === 'Jumbo');
      const TenYrARMRates = TenYrRates.filter(row => row.LoanType === '5/6 ARM' || row.LoanType === '7/6 ARM' || row.LoanType === '10/6 ARM');

      this.setState({
        loading: false,
        ThirtyYrFixed: this.state.ThirtyYrFixed ? true : ThirtyYrFixedRates.length > 0,
        ThirtyYrFHA: this.state.ThirtyYrFHA ? true : ThirtyYrFHARates.length > 0,
        ThirtyYrVA: this.state.ThirtyYrVA ? true : ThirtyYrVARates.length > 0,
        ThirtyYrJumbo: this.state.ThirtyYrJumbo ? true : ThirtyYrJumboRates.length > 0,
        ThirtyYrARM: this.state.ThirtyYrARM ? true : ThirtyYrARMRates.length > 0,
        TwentyYrFixed: this.state.TwentyYrFixed ? true : TwentyYrFixedRates.length > 0,
        TwentyYrFHA: this.state.TwentyYrFHA ? true : TwentyYrFHARates.length > 0,
        TwentyYrVA: this.state.TwentyYrVA ? true : TwentyYrVARates.length > 0,
        TwentyYrJumbo: this.state.TwentyYrJumbo ? true : TwentyYrJumboRates.length > 0,
        TwentyYrARM: this.state.TwentyYrARM ? true : TwentyYrARMRates.length > 0,
        FifteenYrFixed: this.state.FifteenYrFixed ? true : FifteenYrFixedRates.length > 0,
        FifteenYrFHA: this.state.FifteenYrFHA ? true : FifteenYrFHARates.length > 0,
        FifteenYrVA: this.state.FifteenYrVA ? true : FifteenYrVARates.length > 0,
        FifteenYrJumbo: this.state.FifteenYrJumbo ? true : FifteenYrJumboRates.length > 0,
        FifteenYrARM: this.state.FifteenYrARM ? true : FifteenYrARMRates.length > 0,
        TenYrFixed: this.state.TenYrFixed ? true : TenYrFixedRates.length > 0,
        TenYrFHA: this.state.TenYrFHA ? true : TenYrFHARates.length > 0,
        TenYrVA: this.state.TenYrVA ? true : TenYrVARates.length > 0,
        TenYrJumbo: this.state.TenYrJumbo ? true : TenYrJumboRates.length > 0,
        TenYrARM: this.state.TenYrARM ? true : TenYrARMRates.length > 0,
      });
		}
		else {
			this.setError("Could not retrieve rates");
		}
  })

  }

  render() {
    //console.log(this.state)

    return (
      <div
        style={{
          height: '25rem',
          width: '100%',
          background: 'linear-gradient(to bottom, #FF9144 0%, #F47C28 100%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: Colors.white100,
          fontSize: '130%',
          fontWeight: Weights.bold,
        }}
      >
        <div className="text-center">
          {this.state.loading ? (
            <Icon icon="spinner" pulse />
          ) : (
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td />
                  {this.state.Fixed && <td>Fixed</td>}
                  {this.state.FHA && <td>FHA</td>}
                  {this.state.VA && <td>VA</td>}
                  {this.state.Jumbo && <td>Jumbo</td>}
                  {this.state.ARM && <td>ARM</td>}
                </tr>
                {this.state.ThirtyYr && (
                  <tr>
                    <td>30 Yrs</td>
                    {this.state.Fixed && <td>{this.state.ThirtyYrFixed ? <Icon icon="check" /> : <></>}</td>}
                    {this.state.FHA && <td>{this.state.ThirtyYrFHA ? <Icon icon="check" /> : <></>}</td>}
                    {this.state.VA && <td>{this.state.ThirtyYrVA ? <Icon icon="check" /> : <></>}</td>}
                    {this.state.Jumbo && <td>{this.state.ThirtyYrJumbo ? <Icon icon="check" /> : <></>}</td>}
                    {this.state.ARM && <td>{this.state.ThirtyYrARM ? <Icon icon="check" /> : <></>}</td>}
                  </tr>
                )}
                {this.state.TwentyYr && (
                  <tr>
                    <td>20 Yrs</td>
                    {this.state.Fixed && <td>{this.state.TwentyYrFixed ? <Icon icon="check" /> : <></>}</td>}
                    {this.state.FHA && <td>{this.state.TwentyYrFHA ? <Icon icon="check" /> : <></>}</td>}
                    {this.state.VA && <td>{this.state.TwentyYrVA ? <Icon icon="check" /> : <></>}</td>}
                    {this.state.Jumbo && <td>{this.state.TwentyYrJumbo ? <Icon icon="check" /> : <></>}</td>}
                    {this.state.ARM && <td>{this.state.TwentyYrARM ? <Icon icon="check" /> : <></>}</td>}
                  </tr>
                )}
                {this.state.FifteenYr && (
                  <tr>
                    <td>15 Yrs</td>
                    {this.state.Fixed && <td>{this.state.FifteenYrFixed ? <Icon icon="check" /> : <></>}</td>}
                    {this.state.FHA && <td>{this.state.FifteenYrFHA ? <Icon icon="check" /> : <></>}</td>}
                    {this.state.VA && <td>{this.state.FifteenYrVA ? <Icon icon="check" /> : <></>}</td>}
                    {this.state.Jumbo && <td>{this.state.FifteenYrJumbo ? <Icon icon="check" /> : <></>}</td>}
                    {this.state.ARM && <td>{this.state.FifteenYrARM ? <Icon icon="check" /> : <></>}</td>}
                  </tr>
                )}
                {this.state.TenYr && (
                  <tr>
                    <td>10 Yrs</td>
                    {this.state.Fixed && <td>{this.state.TenYrFixed ? <Icon icon="check" /> : <></>}</td>}
                    {this.state.FHA && <td>{this.state.TenYrFHA ? <Icon icon="check" /> : <></>}</td>}
                    {this.state.VA && <td>{this.state.TenYrVA ? <Icon icon="check" /> : <></>}</td>}
                    {this.state.Jumbo && <td>{this.state.TenYrJumbo ? <Icon icon="check" /> : <></>}</td>}
                    {this.state.ARM && <td>{this.state.TenYrARM ? <Icon icon="check" /> : <></>}</td>}
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
}

export default LoanOptions;
