import React from 'react';

import MapQuote from './MapQuote';
import { quoteApiUrlLenderPrice } from '@capcenter/shared';

export default class Quote extends React.Component<{
  ready: boolean;
  readyFields: any;
  fields: any;
  children: (props: any) => React.ReactElement<any>;
}> {
  readonly state = {
    quotes: [],
    mapped: {},
    prevReady: false
  };

  componentDidUpdate() {
    if (this.props.ready !== this.state.prevReady) {
      if (this.props.ready) {
        this.getQuote();
      }

      this.setState({ prevReady: this.props.ready });
    }
  }

  getQuote = () => {
    const newMapped = MapQuote(this.props.readyFields);
    const url: string = quoteApiUrlLenderPrice + 'v1/request-savings/';

    if (JSON.stringify(newMapped) !== JSON.stringify(this.state.mapped)) {
      this.setState({ mapped: newMapped });
      fetch(url, {method:"POST",
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      }, body: newMapped}).then(response => response.json()).then((quotes: any) => {
        if (JSON.stringify(quotes) !== JSON.stringify(this.state.quotes)) this.setState({ quotes: [quotes] });
      });
    }
  };

  render() {
    return <>{this.props.children({ quotes: this.state.quotes })}</>;
  }
}
