import React from 'react';
import {
  ImageCapCenterHQ,
  ImageCapCenterTeam,
  ImageCapCenterCoupleInKitchen,
  ImageCapCenterFamilyHome,
  ImageHero,
  ImageHomesForSale,
  ImageRecentlySoldHomes,
  ImageHomeSalesCalculator,
  ImageHomeValueEstimateTool,
  ImageSold2,
  ImageHunting,
  ImageRefi,
  ImageSellFlyer,
  ImageSellMobile,
  ImageSellOpenHouse,
  ImageServiceArea,
  ImageSellPaperOnePercent,
} from '@capcenter/shared-legacy';
import { AppList, SellPrompt, getUrlWithUtmQueryString } from '@capcenter/shared';

const rates = {
  //	title:"Great Rates. No Gimmicks.",
  //	text:["Many lenders claim low rates but then"," you pay thousands more at closing. Not us."],
  linkText: 'See more rates',
  link: '/mortgage-rates/virginia/richmond/purchase/all',
  type: 'rates',
};

const loanOptions = {
  title: 'Our loan options',
  text: ['We specialize in conventional, FHA, VA, jumbo, and adjustable rate mortgages with various terms. You can use our mortgage services with or without realty services and vice versa.' +
  '<br /><br />CapCenter offers <a href="' + getUrlWithUtmQueryString('/legal/product-offer-details#extended-lock') + '" target="_blank">extended rate locks and floating rate locks</a> to help you purchase new construction homes.' +
  '<br /><br />CapCenter also offers additional loan products with great rates and flexible down payment options, as low as 3% for conventional loans, that are not\n' +
  ' integrated with our online calculators, currently. <a href="tel:18009685844">Call us</a> directly to apply to our <a href="https://blog.capcenter.com/faq/what-is-conventional-97-fannie-97-freddie-97-mortgage" target="_blank">Conventional 97</a>, <a href="https://blog.capcenter.com/faq/does-capcenter-offer-homeready-and-home-possible-products" target="_blank">HomeReady and Home Possible®</a> products.' +
  '<br />'],
  linkText: 'Get a free, anonymous quote >',
  link: '/mortgage-calculator',
  type: 'loanoptions',
};

const loanOptionsRefi = {
  title: 'Our loan options',
  text: ['<div class="text-left">We specialize in conventional, FHA, VA, jumbo, and adjustable rate mortgages with various terms.<br /><br />' +
  'CapCenter can close your standard rate & term refinance loan in as little as two weeks. Cash out refinance, second homes, and investment properties may require additional fees.'],
  linkText: 'Get a free, anonymous quote >',
  link: '/mortgage-calculator',
  type: 'loanoptions',
};

const serviceArea = {
  title: 'Our service area',
  text: ['<div class="text-left">CapCenter currently offers services in Virginia, District of Columbia, Florida, Georgia, Maryland, North Carolina, Ohio, and <span style="white-space: nowrap;">South Carolina.</span> We\'re rapidly expanding our realty services within these markets to provide the best CapCenter experience.</div>'],
  linkText: 'See what services we offer near you >',
  link: '/service-area',
  type: 'servicearea',
  bigLink: true,
  image: ImageServiceArea,
  hideBottomPadding: true,
};

const applyNow = {
  title: 'Apply now with confidence',
  text: ['Thank you for considering CapCenter as your financial partner. Whether you\'re looking for <a href="' + getUrlWithUtmQueryString('/best-realtor-buy-home') + '">realty purchase</a>, <a href="' + getUrlWithUtmQueryString('/sell-a-home') + '">realty sell</a>, <a href="' + getUrlWithUtmQueryString('/buy-a-home') + '">purchase mortgage</a>, <a href="' + getUrlWithUtmQueryString('/refinance') + '">refinance mortgage</a>, or <a href="' + getUrlWithUtmQueryString('/insurance-quote/savings') + '">insurance</a> services, our team is here to help when you are ready to apply.'],
  image: ImageCapCenterTeam,
  link: '/apply-now',
  linkText: 'Apply now with CapCenter >',
};

const applyNowPurchase = {
  title: 'Get pre-approved',
  text: ['Thank you for considering CapCenter as your financial partner. Our team is here to help when you are ready to purchase a home. The application usually takes 15-20 minutes and you can get a pre-approval within one hour, on average, during business hours.'],
  image: ImageCapCenterTeam,
  link: '/apply-now/purchase',
  linkText: 'Apply for a pre-approval with CapCenter >',
};

const applyNowRefinance = {
  title: 'Apply for refinancing',
  text: ['Thank you for considering CapCenter as your financial partner. Our team is here to help when you are ready to apply. The application usually takes 15-20 minutes and closing can take as little as two weeks.'],
  image: ImageCapCenterTeam,
  link: '/apply-now/refinance',
  linkText: 'Apply to refinance your mortgage with CapCenter >',
};

const applyNowBuyRealty = {
  title: 'Start your journey',
  text: ['Thank you for considering CapCenter as your trusted advisor. Our team is here to help you through the home buying process.'],
  image: ImageCapCenterTeam,
  link: '/forms/request-buyers-agent',
  linkText: 'Talk with a realtor >',
};

const applyNowListRealty = {
  title: 'List your home',
  text: ['Thank you for considering CapCenter as your trusted advisor. Our team is here to help when you are ready to list.'],
  image: ImageCapCenterTeam,
  link: '/forms/request-listing-agent',
  linkText: 'List your home with CapCenter >',
};

const buyHomeSearch = {
  title: 'Discover homes for sale',
  text: ['Explore homes for sale in our service areas, calculate CapCenter savings, and schedule a tour easily.'],

  bigLink: true,
  image: ImageHomesForSale,
  link: '/homes/',
  linkTarget: '_blank',
  linkText: 'Search for homes to tour >',
};

const buyRealtors = {
  title: 'Top 1% of realty agents by volume',
  text: ['Our top-rated, experienced REALTORS® are in the top 1% of real estate agents by volume, averaging more than 30 transactions per year. Non-CapCenter agents may take several years to achieve 30 transactions.<br /><br />CapCenter\'s REALTORS® have seen it and done it and are backed by our support teams, including mortgage experts, to guide your home purchase with Zero Closing Costs and Zero Hassles.<br /><br />You can hire CapCenter realty with or without a mortgage and vice versa. However, if you enjoyed our unbelievable Zero Closing Costs mortgage before, you may be surprised to hear that our Realty+Mortgage bundle is our most acclaimed product.<br /><br />Check out our top-rated team members on <a href="https://www.zillow.com/profile/CapCenterLLC" target="_blank">CapCenter\'s Zillow profile</a>, learn more about <a href="https://faq.capcenter.com/article/68-what-is-the-advantage-of-hiring-a-capcenter-realtor" target="_blank">CapCenter Realty\'s advantages</a>, or see <a href="' + getUrlWithUtmQueryString('/homes/location/city/richmond-va/filters/propertyType=CapCenterRealty') + '" target="_blank">homes transacted with CapCenter Realty</a>.'],
  type: 'ourteam',
  team: 'realty',
  link: '/forms/request-buyers-agent',
  linkText: 'Talk with a realtor >',
};


const homeBuyersGuide = {
  title: 'Home buyer\'s guide',
  text: ['<div class="text-left">You can streamline the home buying process with a pre-approval from CapCenter. Unsure where to go from there? We put together a full guide.</div>'],
  image: ImageCapCenterFamilyHome,
  link: '/guides/home-buyers-guide',
  linkText: 'Read our home buyer\'s guide >',
};

const homeSellersGuide = {
  title: 'Home seller\'s guide',
  text: ['<div class="text-left">Selling a home seems easy enough. But there are plenty of things that can pop up. We\'ll help you understand what to expect.</div>'],
  image: ImageCapCenterCoupleInKitchen,
  link: '/guides/home-sellers-guide',
  linkText: 'Read our home seller\'s guide >',
};

const refinanceGuide = {
  title: 'Refinance guide',
  text: ['Learn more about the mortgage refinance process before you get started.'],
  image: ImageCapCenterCoupleInKitchen,
  link: '/guides/refinance-guide',
  linkText: 'Read our refinance guide >',
};

const listHomeSearch = {
  title: 'Analyze recently sold homes',
  text: ['Explore recently sold homes in our service area to see the latest trends in your neighborhood.'],

  bigLink: true,
  image: ImageRecentlySoldHomes,
  link: '/homes/location/geo/37.6153,-77.5979,37.4666,-77.3678,12/filters/propertyType=RecentlySold',
  linkText: 'See recently sold homes >',
  linkTarget: '_blank',
};


const listSalesCalculator = {
  title: 'Estimate sales proceeds',
  text: ['Estimate your home sale net proceeds with CapCenter before selling your home.'],

  image: ImageHomeSalesCalculator,
  bigLink: true,
  link: '/home-sale-estimate/valuation',
  linkText: 'Try our home sales calculator >',
};

const listHomeEstimate = {
  title: 'Estimate home value',
  text: ['Our home value estimate tool uses public data, including tax and market data, to estimate the value of your home.'],

  image: ImageHomeValueEstimateTool,
  bigLink: true,
  link: '/home-sale-estimate/valuation',
  linkText: 'Try our home value estimator >',
};

const listRealtors = {
  title: 'Top 1% of realty agents by volume',
  text: ['Our top-rated, experienced REALTORS® are in the top 1% of real estate agents by volume, averaging more than 30 transactions per year. Non-CapCenter agents may take several years to achieve 30 transactions.<br /><br />CapCenter\'s REALTORS® have seen it and done it and are backed by our support teams, including mortgage experts, to land your home sale quickly for a great price with our 0% List bundle and Zero Hassles.<br /><br />You can hire CapCenter realty with or without a mortgage and vice versa. However, if you enjoyed our unbelievable Zero Closing Costs mortgage before, you may be surprised to hear that our Realty+Mortgage bundle is our most acclaimed product.<br /><br />Check out our team on <a href="https://www.zillow.com/profile/CapCenterLLC" target="_blank">CapCenter\'s Zillow profile</a>, learn more about <a href="https://faq.capcenter.com/article/68-what-is-the-advantage-of-hiring-a-capcenter-realtor" target="_blank">CapCenter Realty\'s advantages</a>, or see <a href="' + getUrlWithUtmQueryString('/homes/location/city/richmond-va/filters/propertyType=CapCenterRealty') + '" target="_blank">homes transacted with CapCenter Realty</a>.'],
  type: 'ourteam',
  team: 'realty',
  link: '/forms/request-listing-agent',
  linkText: 'Talk with a realtor >',
};

const frequentlyAskedQuestions = {
  title: 'Do you have any questions?',
  text: ['<a href="' + getUrlWithUtmQueryString('/contact') + '">Contact us</a> via web chat, phone <span style="white-space: nowrap;"><a href="tel:18009685844">1-800-968-5844</a></span>, or email <a href="mailto:customerservice@capcenter.com">customerservice@capcenter.com</a>, or <a href="https://blog.capcenter.com/faq" target="_blank">search our knowledge base</a> of commonly asked questions.'],
  linkText: 'Search our knowledge base >',
  type: 'faq',
  image: ImageCapCenterHQ,
};

const reviews = {
  title: 'Five-star customer reviews',
  text: ['95% of our clients would recommend us to a family member or a friend.<br /><br />Read or watch CapCenter\'s client reviews on <a href="https://www.trustpilot.com/review/www.capcenter.com" target="_blank">Trustpilot</a>, <a href="https://goo.gl/maps/yD6wUNqCnUP75RYj8" target="_blank">Google</a>, <a href="https://www.zillow.com/profile/CapCenterLLC" target="_blank">Zillow</a>, or <a href="https://www.youtube.com/channel/UCoFRaNzAh-9NkVRxe2eTNwA/videos" target="_blank">YouTube</a>.'],
  type: 'trustpilot',
};

const testimonials = {
  title: 'Client testimonals',
  text: ['"The whole process was transparent from start to finish."'],
  type: 'testimonials',
  link: 'https://www.youtube.com/channel/UCoFRaNzAh-9NkVRxe2eTNwA/videos',
  linkText: 'Watch more video testimonials >',
  linkTarget: '_blank',
};

const fullServiceListing = {
  title: 'Full-service realty listings',
  text: [
    '<div class="rtb-sub-title">Consultation to learn your needs</div><p class="mb-4">We learn about your needs, wants, and constraints to understand your perspective and align with your vision.</p>'
    + '<div class="rtb-sub-title">Curated agent matching</div><p class="mb-4">We match you with an agent that has the most insight in your neighborhood and best meets your needs. You can also request a specific team member.</p>'
    + '<div class="rtb-sub-title">Professional home value estimate based on real-time insights</div><p class="mb-4">We professionally value your home using our proprietary comparative market analysis tool that incorporates real-time market data.</p>'
    + '<div class="rtb-sub-title">Home preparation to maximize value</div><p class="mb-4">We conduct a walkthrough and provide home preparation tips. We also offer home staging services and accompanied showings when beneficial.</p>'
    + '<div class="rtb-sub-title">Official and unofficial listing services</div><p class="mb-4">We list your home on the "official" MLS and various home search engines with your approved pricing, property description, and professional photography.</p>'
    + '<div class="rtb-sub-title">Omni-channel marketing</div><p class="mb-4">We market your home using our established networks and marketing channels such as the official MLS, listing flyers, email marketing, social media, open houses, and our home search tool with professional-grade photography.</p>'
    + '<div class="rtb-sub-title">Coordinated tours</div><p class="mb-4">We use a mobile app to coordinate tours that you can view, approve, or reject.</p>'
    + '<div class="rtb-sub-title">Mobile-friendly document signing</div><p class="mb-4">With our guidance and your direction, we can prepare documents such as offers, sale agreements, and contract addendums that you can review and sign via mobile device.</p>'
    + '<div class="rtb-sub-title">Transaction guidance and negotiation expertise</div><p class="mb-4">We guide you through the sale agreement process and provide insights to help you maximize value with less stress. We share our experience on negotiated items to help you understand whether requests are typical or aggressive.</p>'
    + '<div class="rtb-sub-title">Strong network of top-rated professionals</div><p class="mb-4">We have a strong network of top-rated contractors to address repair requests from home inspections.</p>'
    + '<div class="rtb-sub-title">One-stop shop</div><p class="mb-4">When you bundle our services such as realty, mortgage, and insurance, we provide even more convenience and savings. At CapCenter, we don\'t believe full service equates to full fee.</p>'
  ],
  images: [ImageSellPaperOnePercent, ImageSellMobile, ImageSellFlyer, ImageSellOpenHouse],
  link: '/forms/request-listing-agent',
  linkText: 'Talk with a realtor >',
};

const fullServicePurchase = {
  title: 'Full-service realty purchases',
  text: [
    '<div class="rtb-sub-title">Consultation to learn your needs</div><p class="mb-4">We learn about your home needs, wants, and constraints to understand your perspective and align with your vision.</p>'
    + '<div class="rtb-sub-title">Curated agent matching</div><p class="mb-4">We match you with an agent that has the most insight in the relevant neighborhoods and best meets your needs. You can also request a specific team member.</p>'
    + '<div class="rtb-sub-title">Email alerts</div><p class="mb-4">We set up email alerts with direct access to your local MLS for real-time access to new and modified home listings matching your criteria.</p>'
    + '<div class="rtb-sub-title">Coordinated tours</div><p class="mb-4">We schedule tours that meet your schedule. We know that every day matters. Our team supports each other if needed.</p>'
    + '<div class="rtb-sub-title">Pre-offer property analysis</div><p class="mb-4">We accompany you on home tours, and while you tour the home to see whether it suits your needs, we are actively evaluating the condition of the home. We use our experience to find and share conditional red flags that may not be obvious and common findings that we see in similar properties to help you make informed decisions.</p>'
    + '<div class="rtb-sub-title">Real-time market insights</div><p class="mb-4">We share our latest market insights on homes we tour. We collaborate to gather data and analyze real-time market conditions in each neighborhood and market we are active in. These insights are aggregated by our principal broker, who analyzes transactions across all markets for your benefit.</p>'
    + '<div class="rtb-sub-title">Mobile-friendly document signing</div><p class="mb-4">With our guidance and your direction, we prepare and submit offers and contract addendums that you can review and sign via mobile device.</p>'
    + '<div class="rtb-sub-title">Transaction guidance and negotiation expertise</div><p class="mb-4">We seamlessly guide you through the home purchase agreement and provide insights to help you maximize value with less stress. We share our experience on negotiated items to help you understand whether requests are typical or aggressive.</p>'
    + '<div class="rtb-sub-title">Strong network of top-rated professionals</div><p class="mb-4">We have a strong network of mortgage professionals, home inspectors, independent contractors, radon testers, pest inspectors, appraisers, settlement agents, and insurance carriers.</p>'
    + '<div class="rtb-sub-title">One-stop shop</div><p class="mb-4">When you bundle our services such as realty, mortgage, and insurance, we provide even more convenience and savings. At CapCenter, we don\'t believe full service equates to full fee.</p>'
  ],
  images: [ImageSellPaperOnePercent, ImageSellMobile, ImageSellFlyer, ImageSellOpenHouse],
  link: '/forms/request-buyers-agent',
  linkText: 'Talk with a realtor >',
};

const numberone = {
  type: 'numberone'
};

const whycapcenter = {
  title: 'See why people choose CapCenter',
  text: [
  '<div class="d-flex flex-column">' +
  '<div class="d-flex flex-row align-content-start align-items-stretch flex-wrap text-center justify-content-center">' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">26 years</div><div class="key-data-caption">of savings & service</div></div></div>' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">50,000+</div><div class="key-data-caption">clients served</div></div></div>' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">95%</div><div class="key-data-caption">of clients would recommend to family and friends*</div></div></div>' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">$4,347</div><div class="key-data-caption">average savings*</div></div></div>' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">A+ Rating</div><div class="key-data-caption">on Better Business Bureau (BBB)</div></div></div>' +
  '</div>' +
  '</div>' +
  '<div class="key-data-details">* 95% recommendation based on feedback from client satisfaction scores. Average savings calculated based on average purchase mortgage loan amount and savings based on industry averages.</div>'],
  type: 'whycapcenter'
};


const whycapcenterpurchase = {
  title: 'The CapCenter difference',
  text: [
    '<div class="d-flex flex-column">' +
    '<div class="d-flex flex-row align-content-start align-items-stretch flex-wrap text-center justify-content-center">' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">One-stop shop</div><div class="key-data-caption">all services under one roof for your convenience</div></div></div>' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">Unmatched</div><div class="key-data-caption">hassle-free savings<br/><br/><a href="' + getUrlWithUtmQueryString('/mortgage-calculator/purchase') + '">See details ></a></div></div></div>' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">$12+ billion</div><div class="key-data-caption">in originated loans</div></div></div>' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">$1+ billion</div><div class="key-data-caption">in transacted realty values</div></div></div>'

  ],
  type: 'whycapcenterpurchase'
};

const whycapcenterpurchasemortgage = {
  title: 'The CapCenter Mortgage difference',
  text: [
    '<div class="d-flex flex-column">' +
    '<div class="d-flex flex-row align-content-start align-items-stretch flex-wrap text-center justify-content-center">' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">Unmatched</div><div class="key-data-caption">hassle-free savings<br/><br/><a href="' + getUrlWithUtmQueryString('/mortgage-calculator/purchase') + '">See details ></a></div></div></div>' +

    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">Fast</div><div class="key-data-caption">we can close in as little as 10 days</div></div></div>' +

    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">Optimized</div><div class="key-data-caption">communication across mortgage, realty, and insurance</div></div></div>' +

    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">$12+ billion</div><div class="key-data-caption">in originated loans</div></div></div>' +

    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">Guaranteed</div><div class="key-data-caption">on-time closing<br/><br/><a href="' + getUrlWithUtmQueryString('/content/more/disclosures') + '">See details ></a></div></div></div>'

    ],
  type: 'whycapcenterpurchase'
};


const whycapcenterrealtybuy = {
  title: 'The CapCenter Realty difference',
  text: [
    '<div class="d-flex flex-column">' +
    '<div class="d-flex flex-row align-content-start align-items-stretch flex-wrap text-center justify-content-center">' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">$1+ billion</div><div class="key-data-caption">in transacted realty values</div></div></div>' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">Dedicated</div><div class="key-data-caption">seven days a week</div></div></div>' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">Analytics</div><div class="key-data-caption">provided by brokers for informed decisions</div></div></div>' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">Mobile-friendly</div><div class="key-data-caption">reviewing and signing, anywhere</div></div></div>'
  ],
  type: 'whycapcenterpurchase'
};


const whycapcenterrealtylist = {
  title: 'The CapCenter Realty difference',
  text: [
    '<div class="d-flex flex-column">' +
    '<div class="d-flex flex-row align-content-start align-items-stretch flex-wrap text-center justify-content-center">' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">1% Listing Fees</div><div class="key-data-caption">0% listing fees when you list, buy, and finance with CapCenter</div></div></div>' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">$1+ billion</div><div class="key-data-caption">in transacted realty values</div></div></div>' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">Dedicated</div><div class="key-data-caption">seven days a week</div></div></div>' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">Analytics</div><div class="key-data-caption">provided by brokers for informed decisions</div></div></div>' +
    '<div class="d-flex justify-content-center" style="flex:0; flex-grow: 1; flex-shrink: 1; min-width: 180px;"><div class="d-block key-data-card"><div class="key-data-title">Mobile-friendly</div><div class="key-data-caption">reviewing and signing, anywhere</div></div></div>'
  ],
  type: 'whycapcenterlist'
};

const pages = [
  {
    path: '/',
    app: AppList.capcenter,
    image: ImageHero,
    compareSavings: true,
    rtbs: [
      rates,
      {
        title: 'We\'re Mortgage, Realty, and Insurance all in one place.',
        text: ["<div class=\"text-left\">We are top-rated professionals that are motivated to provide you the best, holistic service efficiently. " +
        "Come to CapCenter when you are ready to <a href=\"" + getUrlWithUtmQueryString("/best-realtor-buy-home") + "\">buy</a> or <a href=\"" + getUrlWithUtmQueryString("/sell-a-home") + "\">sell</a> your home or when you need a <a href=\"" + getUrlWithUtmQueryString("/buy-a-home") + "\">new mortgage</a> or want to <a href=\"" + getUrlWithUtmQueryString("/refinance") + "\">refinance</a> your existing mortgage. " +
        "We can handle your home owners policy and your auto and life <a href=\"" + getUrlWithUtmQueryString("/insurance-quote/savings") + "\">insurance</a>. We do it all. " +
        "<br/><br/>With our <a href='" + getUrlWithUtmQueryString("/buy-a-home") + "'>Zero Closing Cost mortgage offer</a> and <a href='" + getUrlWithUtmQueryString("/sell-a-home") + "'>0% Realtor listing bundle</a>, you'll receive top-rated services and save thousands.</div>"],
        type: 'ourteam',
      },
      numberone,
      whycapcenter,
      whycapcenterpurchase,
      //reviews,
      testimonials,
      serviceArea,
      applyNow,
      frequentlyAskedQuestions
      /*
			{
				title:"About Us",
				text:["We've helped over 40,000 people ","buy, refinance or sell their homes."],
				linkText:"Find Out More",
				link:"/content/more/about_us",
				type: "ourteam",
				team:"about",
				bigLink: true,
			},
*/
    ],
  },
  {
    path: '/mortgage-calculator/purchase?',
    app: AppList.buyAHome,
    image: ImageHunting,
    savings: 'buy',
    tooltip: 'Buy now, refinance anytime, always get Zero Closing Costs.',
    actions: [AppList.applyPurchase, AppList.quotePurchase, AppList.homeSearch, AppList.requestBuyersAgent],
    rtbs: [
      {
        linkText: 'See more rates',
        link: '/mortgage-rates/virginia/richmond/purchase/all',
        type: 'rates',
        selectedItem: 0,
      },
      whycapcenterpurchasemortgage,
      loanOptions,
      numberone,
      whycapcenter,
      //reviews,
      serviceArea,
      applyNowPurchase,
      homeBuyersGuide,
      buyHomeSearch,
      frequentlyAskedQuestions
    ],
  },
  {
    path: '/best-realtor-buy-home',
    app: AppList.bestRealtyBuyHome,
    image: ImageHunting,
    savings: 'buy',
    tooltip: 'Buy now, refinance anytime, always get Zero Closing Costs.',
    actions: [AppList.applyPurchase, AppList.quotePurchase, AppList.homeSearch, AppList.requestBuyersAgent],
    rtbs: [
      fullServicePurchase,
      whycapcenterrealtybuy,
      buyRealtors,
      whycapcenter,
      //reviews,
      serviceArea,
      homeBuyersGuide,
      buyHomeSearch,
      applyNowBuyRealty,
      frequentlyAskedQuestions
    ],
  },
  {
    app: AppList.sellAHome,
    image: ImageSold2,
    savings: 'sell',
    tooltip: <SellPrompt />,
    actions: [AppList.requestListingAgent, AppList.homeSaleQuote, AppList.homeValuation, AppList.homesSold],
    rtbs: [
      fullServiceListing,
      whycapcenterrealtylist,
      listRealtors,
      whycapcenter,
      //reviews,
      serviceArea,
      homeSellersGuide,
      listHomeEstimate,
      listSalesCalculator,
      listHomeSearch,
      applyNowListRealty,
      frequentlyAskedQuestions
    ],
  },
  {
    path: '/mortgage-calculator/refinance?',
    app: AppList.refinance,
    image: ImageRefi,
    savings: 'refi',
    actions: [AppList.applyRefinance, AppList.quoteRefinance, AppList.todaysRatesRefi, AppList.requestInsuranceSavings],
    rtbs: [
      {
        linkText: 'See more rates',
        link: '/mortgage-rates/virginia/richmond/refinance/all',
        type: 'rates',
        selectedItem: 1,
      },
      /*
			{
				title: "Quick & Efficient Closings",
				text: ["Our dedicated team can close your"," home purchase in as little as two weeks."],
				linkText: "Get a Free Quote",
				link: "/mortgage-calculator-refi",
				image: ImageMoveIn,
			},
*/
      loanOptionsRefi,
      numberone,
      whycapcenter,
      serviceArea,
      {
        title: 'Salaried loan consultants',
        text: ["We're here to help you make great financial decisions for life, not just make a quick sale."],
        type: 'ourteam',
        team: 'loan',
        linkText: 'Follow our team on Instagram >',
        link: 'https://www.instagram.com/capcenter',
        linkTarget: '_blank'
      },
      //reviews,
      refinanceGuide,
      applyNowRefinance,
      frequentlyAskedQuestions
      /*
{
				title: "Rate Lock Protection",
				text: ["Lorem ipsum dolor sit amet, consectetur adipiscing"," elit, sed do eiusmod tempor incididunt."],
				linkText: "Get a Free Quote",
				link: "/mortgage-calculator-refi",
				image: ImageRateLock,
			},
*/
    ],
  },
];

export { pages };
