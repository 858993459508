import { Tooltip, getUrlWithUtmQueryString } from '@capcenter/shared-legacy';
import { baseUrl, getQuoteRates } from '@capcenter/shared';
import moment from 'moment';
import React from 'react';
import '../Realty.scss';
import { basePricingScenario } from '@capcenter/quote';
import numeral from 'numeral';

class RatesSection extends React.Component {
  state = {
    fha15Rate: undefined,
    fha30Rate: undefined,
    initialized: false,
    ratesLastUpdated: undefined,
    defaultStateParam: {},
  };

  async componentDidMount() {
    if (!this.state.initialized) {

      const defaultStateParam = await basePricingScenario('RatesDefaultStates', 'Purchase');
      this.setState({defaultStateParam: defaultStateParam})


      const url = baseUrl + '/quoteApi/v1/getDefaultGetQuoteRatesRealtyPage';

      try {
        let response = await fetch(url);
        this.mongoDefaultStateRates = await response.json();		 
        } catch (err) {
        console.log(err);
      
        return 'Unexpected Database Error. Please try again later.';
        }
      const purchaseRates = this.mongoDefaultStateRates;

      if (purchaseRates && purchaseRates.Products) {
        const that = this;
        purchaseRates.Products.forEach(function (value, index) {
          if (value.ProductInfo.mortgageType === 'FHA' && value.ProductInfo.terms === 'Terms_180' && value.Rates?.length > 0) {
            that.setState({ fha15Rate: value.Rates[value.Rates.length - 1] });
          } else if (value.ProductInfo.mortgageType === 'FHA' && value.ProductInfo.terms === 'Terms_360' && value.Rates?.length > 0) {
            that.setState({ fha30Rate: value.Rates[value.Rates.length - 1] });
          }
        });
        that.setState({ ratesLastUpdated: purchaseRates.OfficialLastUpdatedTS });
      }

      this.setState({ initialized: true });
    }
  }

  constructor(props) {
    super(props);

  }

  render() {
    return (
      <section className="section-rates rtb-flex">
        <div className="rtb-text">
          <h2>
            <i className="fas fa-percentage"></i> Today's best rates
          </h2>
          <p>
            At CapCenter, we offer market rates with Zero Closing Costs. We publish our rates online daily for full
            transparency.
          </p>

          <div className="mb-3">
            You can reduce your rate by purchasing discount points.{' '}
            <Tooltip
              arrow
              html={
                <div>
                  <p>
                    Mortgage discount points may be valuable if you don't plan to sell or refinance your home for a long
                    time.
                  </p>
                  <p>
                    However, since rates can go up and down, CapCenter typically recommends zero point rates, so you can
                    refinance with minimal sunk costs if rates fall.
                  </p>
                  <p>Also, ask us about our one-year rate reduction program for qualified loan products.</p>
                </div>
              }
            >
              <i className="fas fa-info-circle"></i>
            </Tooltip>
          </div>

          <div className="mb-3">
            Lower rates often mean higher fees. CapCenter works hard to achieve the lowest rates with the lowest fees.{' '}
            <Tooltip
              arrow
              html={
                <div>
                  <p>Don't forget to compare</p>
                  <ol>
                    <li>Lender fees</li>
                    <li>Non-lender fees</li>
                    <li>Terms such as on-time service guarantees</li>
                    <li>
                      Rates but understand rates and APR can be made lower with higher, often hidden, upfront costs and
                      fees
                    </li>
                  </ol>
                  <p>Our online mortgage calculator shows and helps compare rates and fees.</p>
                </div>
              }
            >
              <i className="fas fa-info-circle"></i>
            </Tooltip>
          </div>
          <div>
            <a href={getUrlWithUtmQueryString(`${baseUrl}/mortgage-rates/virginia/richmond/purchase/all`)} className="mr-4">
              <button className="btn-primary d-none d-sm-inline-block" title="See today's rates">
                Today's rates
              </button>
              <button className="btn-primary d-inline-block d-sm-none" title="See today's rates">
                Today's rates
              </button>
            </a>
            <a href={getUrlWithUtmQueryString(`${baseUrl}/apply-now/purchase`)} className="mr-4">
              <button className="btn-secondary d-none d-sm-inline-block" title="Get pre-approved for a mortgage">
                Get pre-approved
              </button>
              <button className="btn-secondary d-inline-block d-sm-none" title="Get pre-approved for a mortgage">
                Pre-approval
              </button>
            </a>
            <a href={getUrlWithUtmQueryString(`${baseUrl}/forms/rate-alerts`)}>
              <button
                className="btn-secondary d-none d-sm-inline-block"
                title="Sign up for mortgage interest rate alerts"
              >
                Sign up for rate alerts
              </button>
              <button
                className="btn-secondary d-inline-block d-sm-none"
                title="Sign up for mortgage interest rate alerts"
              >
                Rate alerts
              </button>
            </a>
          </div>
        </div>
        <div className="box-container-wrapper">
          <div className="box-container" id="ratesBox">
            <div className="d-flex">
              {this.state.fha15Rate ? (
                <div className="box-card">
                  <p className="program">FHA 15-year</p>
                  <div
                    data-aos="zoom-in"
                    data-aos-once="true"
                    data-aos-duration="2000"
                    data-aos-anchor-placement="bottom-bottom"
                    data-aos-anchor="#ratesBox"
                  >
                    <div>
                      <span className="number">{this.state.fha15Rate.BaseRate}%</span>{' '}
                      <Tooltip
                        arrow
                        html={
                          <div>
                            <p>Assumes ${numeral(this.state.defaultStateParam.LoanAmount).format('0,0')} loan for a Single Family Home as Primary Residence in Richmond, VA.</p>
                            <p>
                              This rate includes points. Always check for points, lender fees, non-lender fees, and
                              terms.
                            </p>
                          </div>
                        }
                      >
                        <i className="fas fa-info-circle"></i>
                      </Tooltip>
                    </div>
                    <div className="points">
                      {this.state.fha15Rate.Points} Points{' '}
                      <Tooltip
                        arrow
                        html={
                          <div>
                            <p>
                              Mortgage discount points may be valuable if you don't plan to sell or refinance your home
                              for a long time.
                            </p>
                            <p>
                              However, since rates can go up and down, CapCenter typically recommends zero point rates,
                              so you can refinance without sunk costs if rates fall.
                            </p>
                            <p>Also, ask us about our one-year rate reduction program for qualified loan products.</p>
                          </div>
                        }
                      >
                        <i className="fas fa-info-circle"></i>
                      </Tooltip>
                    </div>
                    <div className="apr">
                      {this.state.fha15Rate.Apr}%{' '}
                      <a href={getUrlWithUtmQueryString(`${baseUrl}/learning/mortgages/what-does-apr-mean`)}>APR</a>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Tooltip
                        arrow
                        html={
                          <div>
                            <p>
                              We don't recommend purely shopping by APR because APR can be manipulated to look lower
                              with more upfront fees.
                            </p>
                            <p>
                              High upfront fees can be amortized over the full term of the loan, e.g. 30-years, against
                              a high loan amount to minimally impact the APR.
                            </p>
                            <p>
                              Fees can also be shifted to higher "standard" non-lender fees to avoid being calculated in
                              the APR calculation.
                            </p>
                          </div>
                        }
                      >
                        <i className="fas fa-info-circle"></i>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {this.state.fha30Rate ? (
                <div className="box-card">
                  <p className="program">FHA 30-year</p>
                  <div
                    data-aos="zoom-in"
                    data-aos-once="true"
                    data-aos-duration="2000"
                    data-aos-anchor="#ratesBox"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    <div>
                      <span className="number">{this.state.fha30Rate.BaseRate}%</span>{' '}
                      <Tooltip
                        arrow
                        html={
                          <div>
                            <p>Assumes ${numeral(this.state.defaultStateParam.LoanAmount).format('0,0')} loan for a Single Family Home as Primary Residence in Richmond, VA</p>
                            <p>
                              This rate includes points. Always check for points, lender fees, non-lender fees, and
                              terms.
                            </p>
                          </div>
                        }
                      >
                        <i className="fas fa-info-circle"></i>
                      </Tooltip>
                    </div>
                    <div className="points">
                      {this.state.fha30Rate.Points} Points{' '}
                      <Tooltip
                        arrow
                        html={
                          <div>
                            <p>
                              Mortgage discount points may be valuable if you don't plan to sell or refinance your home
                              for a long time.
                            </p>
                            <p>
                              However, since rates can go up and down, CapCenter typically recommends zero point rates,
                              so you can refinance without sunk costs if rates fall.
                            </p>
                            <p>Also, ask us about our one-year rate reduction program for qualified loan products.</p>
                          </div>
                        }
                      >
                        <i className="fas fa-info-circle"></i>
                      </Tooltip>
                    </div>
                    <div className="apr">
                      {this.state.fha30Rate.Apr}%{' '}
                      <a href={getUrlWithUtmQueryString(`${baseUrl}/learning/mortgages/what-does-apr-mean`)}>APR</a>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Tooltip
                        arrow
                        html={
                          <div>
                            <p>
                              We don't recommend purely shopping by APR because APR can be manipulated to look lower
                              with more upfront fees.
                            </p>
                            <p>
                              High upfront fees can be amortized over the full term of the loan, e.g. 30-years, against
                              a high loan amount to minimally impact the APR.
                            </p>
                            <p>
                              Fees can also be shifted to higher "standard" non-lender fees to avoid being calculated in
                              the APR calculation.
                            </p>
                          </div>
                        }
                      >
                        <i className="fas fa-info-circle"></i>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <time className="text-center rates-last-updated d-block" dateTime={this.state.ratesLastUpdated}>
              Rates last updated{' '}
              {this.state.ratesLastUpdated
                ? moment(this.state.ratesLastUpdated).format('MM/DD/YYYY, h:mm A')
                : '...'}
            </time>
          </div>
        </div>
      </section>
    );
  }
}

export default RatesSection;
