import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Colors, Weights } from '@capcenter/shared'
import { Icon, Tooltip } from '@capcenter/shared-legacy'

class SellSavingsTooltip extends Component {
  constructor(props) {
    super(props);

    this.state = {
	    modified: false,
	    hovering: false,
	  }  
	}
	
	modified = () => {
		if(!this.state.modified){
			this.setState({modified: true})
		}
	}
	
	enter = () => {
		this.setState({hovering: true})
	}
	
	leave = () => {
		this.setState({hovering: false})
	}
		
	toggleEditing = () => {	
		
		this.setState({modified: true})
		
		if(this.props.tooltipOpen !== this.props.label){
			this.props.setOpen(this.props.label)
		}else{
			this.props.setOpen("")
		}
	}

	render(){

    return (
	
			<div 
				style={{
					flex: 1, 
					padding: "15px 5px",
					borderTop: "none",
					borderLeft: "1px solid " + Colors.gray350,
					borderRight: "1px solid " + Colors.gray350,
					borderBottom: "1px solid " + Colors.gray350,
					borderBottomLeftRadius: "25px",
					borderBottomRightRadius: "25px",
					marginBottom: "10px",
				}}>

				<strong style={{color: "rgb(244, 124, 40)"}}>{this.props.text}</strong>
				<hr/>
				<Tooltip arrow position="top" distance={10} open={this.state.hovering && !this.state.modified} html={<><Icon icon={["icon","point"]}/> &nbsp;Click to Adjust</>} touchHold={true} >
					<div onMouseEnter={this.enter} onMouseLeave={this.leave}>
						<Tooltip arrow position="bottom" distance={10} open={this.props.tooltipOpen === this.props.label}  interactive={true} html={
							
							<div>
								<div style={{position:"absolute", top:0, right:0, padding: "10px 15px", cursor: "pointer"}} onClick={this.toggleEditing}>
									<Icon icon="times"/>
								</div>
								{this.props.children}
							</div>
							
						}>
							<div>
								<div style={{cursor: "pointer"}} onClick={this.toggleEditing}>
									<div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
										<div>
											{this.props.label} <br className="d-block d-sm-none"/> <span style={{fontVariantNumeric: "tabular-nums"}}>{this.props.value}</span> 
										</div>
										<div>
											<Icon icon="caret-down" color={Colors.primary} style={{fontSize: "200%", marginLeft: 10}}/>
										</div>
									</div>
									<div style={{color: Colors.green500, fontSize: "120%", marginTop: 10, fontWeight: Weights.bold}}>
										Save <span style={{fontVariantNumeric: "tabular-nums"}}>{this.props.savings}</span>
									</div>
								</div>
							</div>
						</Tooltip>
					</div>
				</Tooltip>
					
				
				<div style={{padding: "20px 0px 0px"}} onClick={()=> this.props.tabClick(this.props.link)}>
					<a href={this.props.link} className="btn cc--homepage-get-started-btn" style={{ fontWeight: Weights.bolder, fontStyle: "normal", color: Colors.white100}}>
						Get Started
					</a>
				</div>
			</div>	
		)
	}
}

export default SellSavingsTooltip;