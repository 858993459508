import { quoteApiUrlLenderPrice } from "@capcenter/shared";
import { useEffect, useState } from "react";

export const useFetchBasePricingScenario = (collectionName: string, loanPurpose: string = 'Purchase') => {
   
    const url = quoteApiUrlLenderPrice + `v1/getBasePricingDefaultState/${collectionName}/${loanPurpose}`;
    const [data, setData] = useState(null);

    useEffect(() => {
      fetch(url)
        .then((res) => res.json())
        .then((data) => setData(data));
    }, [url]);
  
    return [data];
}

export const basePricingScenario = async (collectionName: string, loanPurpose: string = 'Purchase') => {
   
    const url = quoteApiUrlLenderPrice + `v1/getBasePricingDefaultState/${collectionName}/${loanPurpose}`;

    try {
        const data = await fetch(url, {
          method: 'GET',
        });
        //console.log(data.json())
        return data.json();
      } catch (err: any) {
        return 'Unexpected Mongo QuoteAPIBasePricingDefaultStates Database Error. Please try again later.';
      }
}
