import React from 'react';
import AppIcon from '../AppIcon';
import AppInt from '../../constants/App.interface';
import './AppHeader.scss';
const AppList = require('../../constants/AppList.json');

const AppHeader: React.SFC<{ appItem?: AppInt; app?: string }> = props => {
  const item = (props.appItem !== undefined && Object.keys(props.appItem).length > 0) ? props.appItem : (props.app ? AppList[props.app] : {});
  if (item === undefined || Object.keys(item).length === 0) return <></>;

  return (
    <div className="app--header">
      <div className="app--header-title">
        <div className="app--header-icon">
          <AppIcon app={item} />
        </div>
        <h1 className={(props.app === 'quotePurchase' || props.app === 'quoteRefinance') ? 'color-capcenter-navy' : ''}>{(item.title)}</h1>
      </div>
      {( item.description && <h2 className="app--header-desc">{item.description}</h2>)}
    </div>
  );
};

export default AppHeader;
