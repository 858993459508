import React from 'react';

import ReactDOM from 'react-dom';
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./InfoButton.scss"

const dollarMask = createNumberMask();
const percentMask = createNumberMask({ prefix: '', suffix: '%', allowDecimal: true})

class Input extends React.Component {
	
	focusInput = () => {
		ReactDOM.findDOMNode(this.input).focus()
	}
	
	render(){
		
		const {label, name, invalid, invalidCheck, value, onChange, type, placeholder, autoComplete, append, appendClick, prepend, prependClick, disabled, onKeyDown, onBlur, onFocus, id, mask, maxLength, hasexternallabel, h100, warning, infoButtonName, infoButtonDescription, readOnly} = this.props;
		
		let thisPlaceholder = false
		let thisMask = ""
		
		if(mask === "phone"){
			
			thisPlaceholder = "(___) ___-____"
			thisMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
			
		}else if(mask === "email"){
			thisPlaceholder = "_@_.___"
			thisMask = emailMask			
		}else if(mask === "ssn"){
			thisPlaceholder = "___-__-____"
			thisMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]			
		}else if(mask === "date"){
			thisPlaceholder = "MM/DD/YYYY"
			thisMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
		}else if(mask === "date-month"){
			thisPlaceholder = "MM/YYYY"
			thisMask = [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
		}else if(mask === "dollar"){
			thisPlaceholder = "$"
			thisMask = dollarMask
		}else if(mask === "percent"){
			thisPlaceholder = "%"
			thisMask = percentMask
		}
	
		
		const inputProps = {
    	mask: thisMask,
    	ref: input => this.input = input,
    	className: `form-control bold ${h100 ? "h-100 " : ""} ` + ( (invalid === true && (value ==="" || invalidCheck)) && "is-invalid"),
    	type: type ? type : "text",
    	name: name,
    	id: id ? id : name,
    	onKeyDown: onKeyDown,
    	onBlur: onBlur,
    	onFocus: onFocus,
    	disabled: disabled,
    	placeholder: placeholder ? placeholder : (thisPlaceholder ? thisPlaceholder :  "Enter " + (label===undefined ? "Label Missing":label)),
    	autoComplete: autoComplete === false ? "off" : (typeof autoComplete === "string" ? autoComplete : name), 
    	value: value, 
    	onChange: (e)=>onChange(e, this.props),
    	style: disabled ? {borderLeft:  0} : invalid ? {} : warning ? {borderColor: '#FFAB00', borderWidth: "2px"} : {borderColor: '#647778'},	
    	maxLength: maxLength ? maxLength : 524288,
		}
		
	
		return (
			<div className={`form-group ${h100 ? "h-100 " : ""}`}>
				{!hasexternallabel && <label htmlFor={name} className={label === undefined ? "alert-danger":"mb-1 bold"}>{label===undefined ? "Label Missing":label}</label>}
				{(infoButtonName !== undefined && infoButtonDescription !== undefined) && (
					<OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip id={infoButtonName} className='infoButton'>
                        {infoButtonDescription}
                      </Tooltip>
                    }
                  >
                    <i className='cc-btn__icon fas fa-info-circle font--sm ml-2' style={{color: '#495F61'}} />
                  </OverlayTrigger>
				)}
				
		    <div className={`${h100 ? "h-100 " : ""} ${(prepend || append) ? " input-group":""}`}>
		    	
		    	{prepend && (
						<div className="input-group-prepend" disabled={disabled} style={{cursor: prependClick ? "pointer" : "text"}} onClick={()=> prependClick ? this.focusInput() : null }>
							{prepend}
						</div>
					)}
				
					{thisMask !== "" ? (
			    	<MaskedInput {...inputProps} readOnly={readOnly ?? false}/>
			    ):(
				    <input {...inputProps} />
			    )}
			    	
		    	{append && (
						<div className="input-group-append" disabled={disabled} style={{cursor: appendClick ? "pointer" : "text"}} onClick={()=> appendClick ? this.input.focus() : null }>
							{append}
						</div>
					)}			    	
			    	
		    </div>
			</div>
		)
	}
}

export default Input