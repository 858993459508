import React from 'react';
import FieldsInt from '@capcenter/quote/src/components/Fields.interface';
import {Colors, Slider, Weights} from "@capcenter/shared";
import {Icon, Tooltip} from "@capcenter/shared-legacy";
import {Link} from "react-router-dom";
import numeral from "numeral";

export default class RefiSavingsTooltipFields extends React.Component<{
  fields: FieldsInt;
  onChange: Function;
  onChangeBoolean: Function;
  LoanPurpose?: Boolean;
  quotes: any;
  availability: any;
  exceptions: any;
  tooltipOpen : Function;
  setOpen : Function;
  tabClick : Function;
  setRefiSavings: Function;
  label: any;
  savings: any;
  sliderValue: any;
  setSliderValue : Function;
  link: any;
  value: any;
  text: any;
}> {
  readonly state = {
    modified: false,
    hovering: false,
    invalid: false,
  };

  componentDidMount() {
    this.props.onChange("Yes", {inputKey: 'CountyRealty'});
  }

  componentDidUpdate() {
    if (this.props.LoanPurpose && this.props.fields.LoanPurpose === '') {
      this.props.onChange(this.props.LoanPurpose, { inputKey: 'LoanPurpose' });
    }
  }

  modified = () => {
    if(!this.state.modified){
      this.setState({modified: true})
    }
  }

  enter = () => {
    this.setState({hovering: true})
  }

  leave = () => {
    this.setState({hovering: false})
  }

  toggleEditing = () => {
    this.setState({modified: true})

    if(this.props.tooltipOpen !== this.props.label){
      this.props.setOpen(this.props.label)
    } else {
      this.props.setOpen("")
    }
  }

  updateValue = (value: number) => {
    this.setState({ modified: true });
    this.props.setSliderValue(value);
  };

  onAfterChange = (value: number) => {
    const price = value / 0.8;
    const loanDollar = value;
    const downDollar = price - loanDollar;
    this.props.onChange(loanDollar, {inputKey: 'LoanAmount'});
    this.props.onChange(downDollar, {inputKey: 'DownPayment'});
    this.props.onChange(price, {inputKey: 'PurchasePrice'});
    this.props.setRefiSavings({
      value: value,
      stickyValue: value,
      modified: true,
      savings: 0,
      query: this.props.fields
    });
  };

  render() {
    return (
      <>
        <div
          style={{
            flex: 1,
            padding: "15px 5px 20px 5px",
            borderTop: "none",
            borderLeft: "1px solid " + Colors.gray350,
            borderRight: "1px solid " + Colors.gray350,
            borderBottom: "1px solid " + Colors.gray350,
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            marginBottom: "10px",
          }}>

          <strong style={{color: "rgb(244, 124, 40)"}}>{this.props.text}</strong>
          <hr/>
          <Tooltip arrow position="top" distance={10} open={this.state.hovering && !this.state.modified} html={<><Icon icon={["icon","point"]}/> &nbsp;Click to Adjust</>} touchHold={true} >
            <div onMouseEnter={this.enter} onMouseLeave={this.leave}>
              <Tooltip arrow position="bottom" distance={10} open={this.props.tooltipOpen === this.props.label}  interactive={true} html={

                <div>
                  <div style={{position:"absolute", top:0, right:0, padding: "10px 15px", cursor: "pointer"}} onClick={this.toggleEditing}>
                    <Icon icon="times"/>
                  </div>


                  <div
                    style={{
                      color: Colors.white100,
                      fontWeight: Weights.bold,
                      fontSize: '120%',
                      padding: 15,
                      width: 260,
                      MozUserSelect: 'none',
                      WebkitUserSelect: 'none',
                      msUserSelect: 'none',
                    }}
                  >
                    <div style={{ fontSize: '120%', fontVariantNumeric: 'tabular-nums', marginBottom: 15 }}>
                      ${numeral(this.props.sliderValue).format('0,0')}
                    </div>
                    <Slider
                      min={100000}
                      value={this.props.sliderValue}
                      max={1000000}
                      onChange={this.updateValue}
                      onAfterChange={this.onAfterChange}
                      step={5000}
                    />
                    Adjust Loan Amount
                    <br/><br/>
                    (30 Year Fixed Rate)
                  </div>
                </div>

              }>
                <div>
                  <div style={{cursor: "pointer"}} onClick={this.toggleEditing}>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <div>
                        {this.props.label} <br className="d-block d-sm-none"/> <span style={{fontVariantNumeric: "tabular-nums"}}>{this.props.value}</span>
                      </div>
                      <div>
                        <Icon icon="caret-down" color={Colors.primary} style={{fontSize: "200%", marginLeft: 10}}/>
                      </div>
                    </div>
                    <div style={{color: Colors.green500, fontSize: "120%", marginTop: 10, fontWeight: Weights.bold}}>
                      Save <span style={{fontVariantNumeric: "tabular-nums"}}>{this.props.savings}</span>
                    </div>
                  </div>
                </div>
              </Tooltip>
            </div>
          </Tooltip>


          <div style={{padding: "20px 0px 0px"}} onClick={()=> this.props.tabClick(this.props.link)}>
            <a href={this.props.link} className="btn  cc--homepage-get-started-btn" style={{ fontWeight: Weights.bolder, fontStyle: "normal", color: Colors.white100}}>
              Get Started
            </a>
          </div>
        </div>
      </>
    );
  }
}
